/* This code snippet is creating a global state provider using React's Context API. It defines a
`GlobalStateProvider` component that wraps its children with a context provider. Within this
provider, it initializes various state variables using the `useState` hook to manage the global
state of the application. */

import React, { createContext, useContext, useState, useEffect } from 'react';
import { machineLearningModelTrainAvgValueAPI } from '../api/api_definitions.js';

const GlobalStateContext = createContext();

export const useGlobalState = () => useContext(GlobalStateContext);


export const GlobalStateProvider = ({ children }) => {

  // ANALISI-MACCHINE-CONNESSE VARIABLE
  const [machineFamilyConnected, setMachineFamilyConnected] = useState(0);
  const [infoMachineConnected, setInfoMachineConnected]     = useState(null);
  const [tableMachineConnected, setTableMachineConnected]   = useState(null);

  // ANALISI-OVERVIEW-MACHINE-LEARNING VARIABLE
  const [machineFamilyML, setMachineFamilyML]                           = useState(0)
  const [infoMachineStatusML, setInfoMachineStatusML]                   = useState(null);
  const [tableMachineStatusML, setTableMachineStatusML]                 = useState(null);
  const [tableMachineStatusThresholds, setTableMachineStatusThresholds] = useState(null);

  // ANALISI-ERRORE-DRIVER VARIABLE
  const [machineFamily, setMachineFamily] = useState(0);

  const [listCustomerMachines, setListCustomerMachines] = useState([]);
  const [customerName, setCustomerName] = useState("");

  const [listSNMachines, setListSNMachines] = useState([]);
  const [snMachine, setSnMachine] = useState("");

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [plotData, setPlotData] = useState([]);
  const [plotDataInfo, setPlotDataInfo] = useState([]);

  // ANALISI-SEGNALI-SENSORI
  const [machineFamilySensors, setMachineFamilySensors] = useState(0)
  const [infoSensors, setInfoSensors] = useState(null)
  const [tableSensors, setTableSensors] = useState(null)
  const [selectedSensors, setSelectedSensors] = useState(null)

  // ANALISI-PRODUZIONE
  const [machineFamilyProduction, setMachineFamilyProduction] = useState(0)
  const [headTypeProduction, setHeadTypeProduction]           = useState("0")
  const [minLaserPowerProduction, setMinLaserPowerProduction] = useState(0) 
  const [maxLaserPowerProduction, setMaxLaserPowerProduction] = useState(15000)
  const [dateRangeProduction, setDateRangeProduction] = useState([new Date("2024/01/01"), new Date()]);
  const [startDateProduction, endDateProduction] = dateRangeProduction;

  const [infoPlotProduction, setInfoPlotProduction] = useState(null)

  // ANALISI-PRODUZIONE-CLIENTE
  const [machineFamilyProductionCustomer, setMachineFamilyProductionCustomer] = useState(0)

  const [listCustomerProductionCustomer, setListCustomerProductionCustomer] = useState([]);
  const [customerNameProductionCustomer, setCustomerNameProductionCustomer] = useState("");

  const [listSNMachinesProductionCustomer, setListSNMachinesProductionCustomer] = useState([]);
  const [snMachineProductionCustomer, setSnMachineProductionCustomer] = useState("");

  const [dateRangeProductionCustomer, setDateRangeProductionCustomer] = useState([null, null]);
  const [startDateProductionCustomer, endDateProductionCustomer] = dateRangeProductionCustomer;

  const [infoPlotProductionCustomer, setInfoPlotProductionCustomer] = useState(null)

  // ANALISI-OVERVIEW-CLIENTE
  const [infoConnectionMachineCustomerID, setInfoConnectionMachineCustomerID]   = useState(null);
  const [tableConnectionMachineCustomerID, setTableConnectionMachineCustomerID] = useState(null);
  const [tableStatusMachineCustomerID, setTableStatusMachineCustomerID] = useState(null);



  // API GATEWAY RESPONSE MANAGEMENT --> GET AVG VALUES OF MACHINE LEARNING MODEL
  useEffect(() => {
    machineLearningModelTrainAvgValueAPI()
    .then((dataAPI) => {
      if (dataAPI !== null) {

        const parsedData = JSON.parse(dataAPI.DF); 

        const categories = Object.keys(parsedData);
        const valuesLabel0 = Object.values(parsedData).map(item => Object.values(item)[0]);
        const valuesLabel1 = Object.values(parsedData).map(item => Object.values(item)[1]);

        let newData = [{
            x: categories,
            y: valuesLabel0,
            type: 'bar',
            name: 'Critico - media dati modello ML',
            marker: {
              color: '#880808',
              width: 1 
            } 
          },
          {
            x: categories,
            y: valuesLabel1,
            type: 'bar',
            name: 'Buono - media dati modello ML',
            marker: {
              color: '#008000',
              width: 1 
            }  
          }]

        setPlotData(newData);
        
        }
      }
    )
    .catch((error) => {
      console.log(error);
    }
    );
  }, [])

  return (
    <GlobalStateContext.Provider
      value={{
        machineFamilyConnected, setMachineFamilyConnected,
        infoMachineConnected, setInfoMachineConnected,
        tableMachineConnected, setTableMachineConnected,
        machineFamilyML, setMachineFamilyML,
        infoMachineStatusML, setInfoMachineStatusML,
        tableMachineStatusML, setTableMachineStatusML,
        tableMachineStatusThresholds, setTableMachineStatusThresholds,
        machineFamily, setMachineFamily,
        listCustomerMachines, setListCustomerMachines,
        customerName, setCustomerName,
        listSNMachines, setListSNMachines,
        snMachine, setSnMachine,
        dateRange, setDateRange,
        startDate, endDate,
        plotData, setPlotData,
        plotDataInfo, setPlotDataInfo,
        machineFamilySensors, setMachineFamilySensors,
        infoSensors, setInfoSensors,
        tableSensors, setTableSensors,
        selectedSensors, setSelectedSensors,
        machineFamilyProduction, setMachineFamilyProduction,
        headTypeProduction, setHeadTypeProduction,
        minLaserPowerProduction, setMinLaserPowerProduction,
        maxLaserPowerProduction, setMaxLaserPowerProduction,
        dateRangeProduction, setDateRangeProduction,
        startDateProduction, endDateProduction,
        infoPlotProduction, setInfoPlotProduction,
        machineFamilyProductionCustomer, setMachineFamilyProductionCustomer,
        listCustomerProductionCustomer, setListCustomerProductionCustomer,
        customerNameProductionCustomer, setCustomerNameProductionCustomer,
        listSNMachinesProductionCustomer, setListSNMachinesProductionCustomer,
        snMachineProductionCustomer, setSnMachineProductionCustomer,
        dateRangeProductionCustomer, setDateRangeProductionCustomer,
        startDateProductionCustomer, endDateProductionCustomer,
        infoPlotProductionCustomer, setInfoPlotProductionCustomer,
        infoConnectionMachineCustomerID, setInfoConnectionMachineCustomerID,
        tableConnectionMachineCustomerID, setTableConnectionMachineCustomerID,
        tableStatusMachineCustomerID, setTableStatusMachineCustomerID
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};
import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import MachineFamilyList from "./MachineFamilyList.js";
import CustomerList from './CustomerList.js';
import SNMachineList from './SNMachineList.js';
import DateRangeSelector from './DateRangeSelector.js';
import ButtonStartAnalysis from './ButtonStartAnalysis.js';
import PlotComponent from "./PlotComponents/PlotComponent.js";

import { useGlobalState } from '../../components/globalStateProvider.js';


// MAIN PAGE
function AnalisiErroreDriverPage() {

  const { machineFamily, listCustomerMachines, customerName, listSNMachines, snMachine} = useGlobalState();

  return (
    <>
      <Container>

        <Row className="text-center mt-4 mb-5">
          <h2>Confronto analisi errore</h2>
          <p className='mt-1'> Questa sezione permette di visualizzare e confrontare i clienti, esaminando la frequenza degli errori rispetto a un periodo di 24h di tempo di attività (RT).
                              Nel grafico, per impostazione predefinita, sono rappresentati due scenari: critico e buono. Viene mostrato, per ciascun errore considerato, il valore medio calcolato 
                              utilizzando i clienti impiegati durante la fase di addestramento.
          </p>
        </Row>
      </Container>

      <Row className="text-center mt-5 mb-4">
        <Col className="ms-5 me-5" xxl={{ span: 2, offset: 0 }}>

          <MachineFamilyList/>

          {machineFamily !== 0 && listCustomerMachines.length > 0 && <CustomerList />}
          {machineFamily !== 0 && customerName !== "" && listSNMachines.length > 0 && <SNMachineList />}
          {machineFamily !== 0 && customerName !== "" && listSNMachines.length > 0 && snMachine !== "" && <DateRangeSelector />}

          <ButtonStartAnalysis />

        </Col>

        <Col md={{ span: 4, offset: 0 }}>
          <PlotComponent />
        </Col>

      </Row>
    </>
  );
}

export default AnalisiErroreDriverPage;
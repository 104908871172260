import React, { useEffect} from "react";
import { Row, Form } from "react-bootstrap";

import { useGlobalState } from '../../components/globalStateProvider';
import { machineSNListSelectedAPI } from '../../api/api_definitions.js';


const CustomerList = () => {
  const { machineFamily, customerName, setCustomerName, listCustomerMachines, setListSNMachines, setSnMachine } = useGlobalState();

    // Reset the info
    useEffect(() => {
      setSnMachine("");
    }, [machineFamily, customerName]); 


   // API GATEWAY RESPONSE MANAGEMENT WITH MEMO (AVOID DOUBLE CALL) --> RETURN LIST OF CUSTOMERS
   const memoizedMachineSNListSelectedAPI = (() => {
    let cachedData = {}; // Cache for storing results
    return async (machineFamily, customerName) => {
      if (!cachedData[machineFamily]) {
        cachedData[machineFamily] = await machineSNListSelectedAPI(machineFamily, customerName);
      }
      return cachedData[machineFamily];
    };
  })();

  useEffect(() => {
    let isMounted = true;
    memoizedMachineSNListSelectedAPI(machineFamily, customerName)
      .then((dataAPI) => {
        if (isMounted) {
          console.log("Data List SN", dataAPI);
          if (dataAPI !== null) {
            setListSNMachines(dataAPI.ListSNMachines);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return () => {
      isMounted = false;
    };
  }, [customerName, setListSNMachines]);

  // Return layout
  return (
    <Row className="text-center mt-4 mb-4">
      <Form.Select
        aria-label="Default select example"
        as="select"
        value={customerName}
        onChange={e => {
          if (e.target.value !== "") {
            setCustomerName(e.target.value);
          }
        }}>
        <option value="" disabled>Seleziona un cliente</option>
        {listCustomerMachines.map((item, index) => (
          <option value={item} key={index}>{item}</option>
        ))}
      </Form.Select>
    </Row>
  );
};

export default CustomerList;
import React from "react";
import { Row, Col, Container, Form } from "react-bootstrap";

import { useGlobalState } from '../../components/globalStateProvider.js';
import MachineInfoConnected from './MachineInfoConnected.js';


// MAIN PAGE
function AnalisiMacchineConnessePage() {

  const { machineFamilyConnected, setMachineFamilyConnected } = useGlobalState();

  return (
    <div>
        <Container>
          <Row className="text-center mt-4 mb-4">
            <h2>Analisi situazione macchine su Fleet</h2>
            <p className="mt-1">Questa sezione esplora e fornisce indicazioni sulla situazione delle macchine in termini di connessione al cloud e sulle versioni dei principali software e applicazioni presenti</p>
          </Row>

          <Row className="text-center mt-4 mb-4">
            <Col md={{ span: 6, offset: 3 }}>
              <Form.Select aria-label="Default select example" as="select"
                           value={machineFamilyConnected}
                           onChange={e => {
                                          if (e.target.value !== "0") {
                                            console.log("e.target.value", e.target.value);
                                            setMachineFamilyConnected(e.target.value);
                                          }
                           }}>
                  <option value="0" disabled>Seleziona la famiglia macchina</option>
                  <option value="1">Laser Genius +</option>
                  <option value="2">Laser Genius Fiber</option>
                  <option value="3">Platino Fiber</option>
                  <option value="4">Platino Linear</option>
              </Form.Select>
            </Col>
          </Row>

          < MachineInfoConnected />
         
        </Container>       

    </div>
  )
}

export default AnalisiMacchineConnessePage;



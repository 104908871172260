import React from "react";
import Plot from 'react-plotly.js';


const ProductionCustomerPlot = ( {values, labels, title} ) => {

  // Combine labels and values into an array of objects
  const combinedArrayValue = labels.map((label, index) => ({ label, value: values[index] }));

  // Sort the combined array by values in descending order
  const sortedArrayValue = combinedArrayValue.sort((a, b) => b.value - a.value);

  // Extract sorted labels and values
  const sortedLabels = sortedArrayValue.map(item => item.label);
  const sortedValues = sortedArrayValue.map(item => item.value);

  // Initialize variables for cumulative time and incremental percentages
  const totalTime    = sortedValues.reduce((acc, val) => acc + val, 0);
  let cumulativeTime = 0;
  const incrementalPercentages = sortedValues.map(value => {
    cumulativeTime += value;
    const incrementalPercent = (cumulativeTime / totalTime) * 100;
    return parseFloat(incrementalPercent.toFixed(2)); // Round to 2 decimal places
  });

  // Determine the length of the data
  const dataLength = sortedValues.length;

  // Generate color array
  const colorArray = [
    ...Array(1).fill('rgb(0, 71, 171)'), // First 5 colors
    ...Array(dataLength - 1).fill('rgb(115, 147, 179)') // Remaining colors
  ];

  // Plot data (Pareto)
  const plotData = [
    {
      x: sortedLabels,
      y: sortedValues,
      type: 'bar',
      marker: {"color": colorArray}, 
      name: title
   },
   {
    x: sortedLabels,
    y: incrementalPercentages,
    type: 'scatter',
    mode: 'lines+markers',
    yaxis: 'y2',
    marker: { color: '#880808', size: 10 },
    line: { color: '#880808', width: 4 },
    name: "Incremental percentage [%]"
  }
   

];

  // Layout data
  const plotLayout = {
    title: {
      text: "Produzione @ " + title,
      font: {
        family: 'Arial, sans-serif', 
        size: 20, 
        color: 'black'
      }
    },
    width: 1400,
    height: 500,
    yaxis: { 
      title: 'Hours', 
      side: 'left',
      rangemode:'tozero'
    },
    yaxis2: {
      title: 'Incremental percentage [%]',
      overlaying: 'y',
      side: 'right',
      rangemode:'tozero'
      },
    legend: {
      orientation: 'h',
      x: 0.45,
      y: -0.1,
      xanchor: 'center',
      yanchor: 'top'
    }
  };

  return (
    <Plot data={plotData} layout={plotLayout}/>
  )
}

export default ProductionCustomerPlot
/**
 * The `ChangePassword` component in React allows users to update their password with error and success
 * message handling.
 * @returns The `ChangePassword` component is being returned. It is a functional component that
 * contains a form for changing the user's password. The form includes input fields for entering the
 * old password and new password, along with a button to trigger the password change process. The
 * component also displays error messages if there are any issues during the password change process
 * and success messages if the password change is successful.
 */
import React, { useState } from 'react';
import { Col, Form, Button, Container } from "react-bootstrap";

import { updatePassword  } from 'aws-amplify/auth';

import './ChangePassword.css'

const ChangePassword = () => {

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');


  const handleChangePassword = async () => {
    try {
        await updatePassword({ oldPassword, newPassword });
        console.log('Password changed successfully');
        setSuccess('Password changed successfully');
        setError('')
    } 
    catch (error) {
      console.log('Error changing password:', error);
      setError(error.message);
      setSuccess('')
    }
  };

  return (
    <div className='my-form-change-password'>
        <Container>
            <Form>
                <Form.Group className="mb-5" controlId="formBasicPassword">
                    <Form.Label className="text-center" style={{width: "100%"}}><h5>Inserisci VECCHIA Password</h5></Form.Label>
                    <Form.Control size="lg" placeholder="Vecchia Password" value={oldPassword}  onChange={(e) => setOldPassword(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-5 mt-5" controlId="formBasicPassword">
                    <Form.Label className="text-center" style={{width: "100%"}}><h5>Inserisci NUOVA Password</h5></Form.Label>
                    <Form.Control size="lg" placeholder="Nuova Password" value={newPassword}  onChange={(e) => setNewPassword(e.target.value)} />
                </Form.Group>

                <Col className="text-center">
                    <Button className="mt-5" style={{width: "40%"}} size="lg" variant="dark" onClick={handleChangePassword}> CAMBIA PASSWORD </Button>
                </Col>

                {error && <h5 className="text-danger mt-5 text-center">{error}</h5>}
                {success && <h5 className="text-success mt-5 text-center">{success}</h5>}
            </Form>
        </Container>
    </div>
  );
};

export default ChangePassword;
import React, { useEffect, useState } from "react";
import { Row, Col, Spinner, ListGroup } from "react-bootstrap";
import Plot from 'react-plotly.js';

import { useGlobalState } from '../../components/globalStateProvider.js';
import MachineTableConnected from './MachineTableConnected.js';
import { machineOnFleetTableAPI } from '../../api/api_definitions.js';


const MachineInfoConnected = () => {

  const [loading, setLoading] = useState(true);
  const [startAnalysis, setStartAnalysis] = useState(false);

  const {
    machineFamilyConnected,
    infoMachineConnected, setInfoMachineConnected,
    tableMachineConnected, setTableMachineConnected
  } = useGlobalState();

  
  // API GATEWAY RESPONSE MANAGEMENT --> Return list of laser machine family connected to cloud
  useEffect(() => {
    setStartAnalysis(true)
    setLoading(true)
    let isMounted = true;
    machineOnFleetTableAPI(machineFamilyConnected)
      .then((dataAPI) => {
        if (isMounted) {
          console.log("Data", dataAPI)
          setInfoMachineConnected(dataAPI)  
          if (dataAPI !== null) {
            setTableMachineConnected(JSON.parse(dataAPI.DF))
          }
          setLoading(false)
        }
      }
     )
      .catch((error) => {
        console.log(error)
        setStartAnalysis(false)
        setLoading(true)
    });
    return () => {
      isMounted = false;
    };
  }, [machineFamilyConnected, setInfoMachineConnected, setTableMachineConnected])

  // Return loading layout
  if (startAnalysis & loading) {
    return (
      <Row className="d-flex flex-column align-items-center justify-content-center text-center">
        <p className="mt-4">Caricamento dati...</p>
        <Spinner animation="border" role="status"></Spinner>
      </Row>
    )
  }

  // Return layout
  if (infoMachineConnected!==null) {

    const lenDF        = Object.values(Object.values(tableMachineConnected)[0]).length;
    const currentPosts = Object.values(tableMachineConnected).map(i => Object.values(i).slice(0, lenDF));
    const colors       = ["darkgreen", "gold", "darkorange", "red"];
    const labels       = ['Macchine Connesse', 'Macchine non connesse da 5 a 9 giorni', 'Macchine non connesse da 10 a 14 giorni', 'Macchine non connesse da più di 15 giorni'];
    const values       = [Number(infoMachineConnected.CountYes), Number(infoMachineConnected.Count5Days), Number(infoMachineConnected.Count10Days), Number(infoMachineConnected.Count15Days)];

    const plotData = [{
      values: values,
      labels: labels,
      type: 'pie',
      marker: { colors: colors },
      sort: false 
    }];

    const plotLayout = {
      width: 500,
      height: 500,
      legend: { 
        itemsizing: 'constant',
        orientation: 'h',
        x: 0.5,
        y: 1.5,
        xanchor: 'center',
        yanchor: 'top'
        }, 
      categoryorder: 'array', 
      categoryarray: labels 
    };
  
    // Return layout
    return (
        <>
          { tableMachineConnected && (
            <>
              <Row className="text-center">
                <Col md={{ span: 6, offset: 3 }}>
                  <h4>Macchine connesse: {Number(infoMachineConnected.CountYes)} / {Number(infoMachineConnected.CountAll)}</h4>
                </Col>
              </Row>

              <Row className="text-center">
                <Col md={{ span: 6, offset: 3 }}>
                  <Plot data={plotData} layout={plotLayout}/>
                </Col>
              </Row>


              <Row className="text-center">
                <Col md={{ span: 6, offset: 3 }}>
                  <h4>Elenco stato di connessione delle macchine</h4>
                </Col>
              </Row>

              <Row className="mt-4 mb-4">
                <MachineTableConnected columns={Object.keys(tableMachineConnected)} posts={currentPosts} />
              </Row>

              <Row className="mt-3 mb-5">
                <h5>Legenda:</h5>
                <ListGroup>
                    <ListGroup.Item style={{ backgroundColor:'rgba(150, 40, 27, 0.9)', color:'white', width:'30%', }}>Macchine non connesse da più di 15gg</ListGroup.Item>
                    <ListGroup.Item style={{ backgroundColor:'rgba(255, 165, 0, 0.9)',width:'30%', }}>Macchine non connesse da 10gg a 14gg</ListGroup.Item>
                    <ListGroup.Item style={{ backgroundColor:'rgba(250, 218, 94, 0.9)',width:'30%', }}>Macchine non connesse da 5gg a 9gg</ListGroup.Item>
                    <ListGroup.Item style={{ width:'30%', }}>Macchine connesse</ListGroup.Item>
                </ListGroup>
              </Row>
            </>
          )}
        </>
      )    
  }

}

export default MachineInfoConnected;

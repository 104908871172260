import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';

const ColumnVisibilityManager = ({ columns, hiddenColumns, toggleColumnVisibility }) => {
  const [showColumnModal, setShowColumnModal] = useState(false);

  return (
    <>
      <Row className="mt-3 mb-3" style={{padding:0}}>
        <Col style={{padding:0}}>
            <Button style={{float: 'right'}} onClick={() => setShowColumnModal(!showColumnModal)}>
              {showColumnModal ? "Chiudi Gestione visibilità colonne" : "Apri Gestione visibilità colonne"}
            </Button>
        </Col>
      </Row>

      {showColumnModal && (
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {columns.map((column, index) => (
              <div key={index} style={{ width: '20%' }}>
                <label>
                  <input
                    type="checkbox"
                    checked={!hiddenColumns.includes(column.name)}
                    onChange={() => toggleColumnVisibility(column.name)}
                  />
                  {column.name}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ColumnVisibilityManager;
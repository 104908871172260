import React from 'react';
import { Accordion, Container, Row, Col } from 'react-bootstrap'; 

const ProductionErrorCustomer = ({ results }) => {
  // Convert results object to array of entries and sort by 'total_count'
  const entries = Object.entries(results).sort((a, b) => b[1].total_count - a[1].total_count);
  const rows = [];

  // Group entries in sets of 2 for each row
  for (let i = 0; i < entries.length; i += 2) {
    const rowEntries = entries.slice(i, i + 2);
    rows.push(rowEntries);
  }

  return (
    <div>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Maggiori informazioni produzione/errore</Accordion.Header>
          <Accordion.Body>
            <Container>
              {rows.map((rowEntries, rowIndex) => (
                <Row key={rowIndex} className="mt-3">
                  {rowEntries.map(([key1, comparisons]) => (
                    <Col md={12} key={key1}>
                      <p>
                        N. errori con <strong style={{ backgroundColor: 'yellow' }}>"{key1}": {comparisons['total_count']}</strong>
                      </p>
                      <ul>
                        {Object.entries(comparisons)
                          .filter(([key2]) => key2 !== 'total_count') // Filter out 'total_count'
                          .sort(([, a], [, b]) => b[0] - a[0]) // Sort key2 entries by percentage in descending order
                          .map(([key2, values]) => (
                            <li key={key2}>
                              <Row className="g-0">
                                <Col md={6}>
                                  <strong>"{key2}":</strong>
                                </Col>
                                <Col md={6}>
                                  {typeof values[0] === 'number' ? values[0].toFixed(2) : values[0]} % -- {typeof values[1] === 'number' ? values[1].toFixed(2) : values[1]} errori/24h di RunningTime.
                                </Col>
                              </Row>                            </li>
                          ))}
                      </ul>
                    </Col>
                  ))}
                </Row>
              ))}
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default ProductionErrorCustomer;
import React, { useEffect,useState } from "react";
import { Container } from "react-bootstrap";

import { getCurrentUser } from 'aws-amplify/auth';

import OverviewInfo from './OverviewInfo.js';


// MAIN PAGE
function OverviewClientePage() {

  const [user, setUser] = useState("")
  const [loading, setLoading] = useState(true)
  
  // Fetch user groups on component mount
  const checkUser = async () => {
    try {
      const user = await getCurrentUser();
      setUser(user.username)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching user groups:', error);
    }
  };

  // Each time the page/component is loaded it retrieves general information and reads the user's access level
  useEffect(() => {
    checkUser(); 
  }, []);

  return (
    <>
      { loading ? (<></>) : (
          <div className='text-center mt-4 mb-4'>
            <Container>
              < OverviewInfo user={user} />        
            </Container>       
          </div>
      ) }
    </>
  );
}

export default OverviewClientePage;



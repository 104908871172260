import React from 'react';
import { Container, Row, Col, Accordion } from "react-bootstrap";

const ProductionDetails = ({ results }) => {
    // Convert results object to an array of entries and sort by 'total_count' in descending order
    const entries = Object.entries(results).sort((a, b) => b[1].total_count - a[1].total_count);
    const rows = [];

    // Group entries in sets of 2 for each row
    for (let i = 0; i < entries.length; i += 2) {
        const rowEntries = entries.slice(i, i + 2);
        rows.push(rowEntries);
    }

    return (
        <div>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Maggiori informazioni produzione/clienti</Accordion.Header>
                    <Accordion.Body>
                        <Container>
                            {rows.map((rowEntries, rowIndex) => (
                                <Row key={rowIndex} className='mt-3'>
                                    {rowEntries.map(([key1, comparisons]) => (
                                        <Col md={6} key={key1}>
                                            <p>Clienti considerati che usano <strong style={{ backgroundColor: 'yellow' }}>"{key1}": {comparisons['total_count']}</strong>, di cui il:</p>
                                            <ul>
                                                {Object.entries(comparisons)
                                                    .filter(([key2]) => key2 !== 'total_count') // Exclude 'total_count' from sorting
                                                    .sort(([, a], [, b]) => b - a) // Sort the key2 entries by percentage in descending order
                                                    .map(([key2, percentage]) => (
                                                        <li key={key2}>
                                                            <strong className="fixed-width-label-pc">{percentage.toFixed(2)}% </strong> usano anche il "{key2}".

                                                        </li>
                                                    ))}
                                            </ul>
                                        </Col>
                                    ))}
                                </Row>
                            ))}
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
};

export default ProductionDetails;
import React, { useState } from 'react';
import { Row, Col, InputGroup, Button, Form } from 'react-bootstrap';

const FilterComponent = ({ filterCustomer, 
                            onFilterCustomer, 
                            filterPLC,
                            onFilterPLC,
                            filterCNC,
                            onFilterCNC,
                            filterTulus,
                            onFilterTulus,
                            filterIOT,
                            onFilterIOT,
                            filterTOB,
                            onFilterTOB,
                            optionsPLC,
                            optionsCNC,
                            optionsTulus,
                            optionsIOT,
                            optionsTOB,
                            onClearCustomer,
                            onClearPLC,
                            onClearCNC,
                            onClearTulus,
                            onClearIOT,
                            onClearTOB
                           }
                          
                          ) => {

  const [showFilter, setShowFilter] = useState(false);
  
  return (

    <>

    <Row className="mt-3 mb-3" style={{padding:0}}>
      <Col style={{padding:0}}>
          <Button style={{float: 'right'}} onClick={() => setShowFilter(!showFilter)}>
            {showFilter ? "Chiudi Gestione Filtro" : "Apri Gestione Filtro"}
          </Button>
      </Col>
    </Row>


    {showFilter && (

      <Row className="text-center mt-2 mb-2" >

        <Col md={4}>
          <InputGroup className="mb-3">
            <Form.Control
              id="search"
              type="text"
              placeholder="Filter By Customer Name"
              aria-label="Search Input"
              value={filterCustomer}
              onChange={onFilterCustomer}
            />
            <Button type="button" onClick={onClearCustomer}> X </Button>
          </InputGroup>
        </Col>

        <Col md={4}>
          <InputGroup className="mb-3">
            <Form.Control
              id="search"
              as="select"
              value={filterPLC}
              onChange={onFilterPLC}
            >
              <option value="">Filter By PLC version</option>
                {optionsPLC.sort().map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
            </Form.Control>

            <Button type="button" onClick={onClearPLC}> X </Button>
          </InputGroup>
        </Col>

        <Col md={4}>
          <InputGroup className="mb-3">
            <Form.Control
              id="search"
              as="select"
              value={filterCNC}
              onChange={onFilterCNC}
            >
              <option value="">Filter By CNC version</option>
                {optionsCNC.sort().map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
            </Form.Control>

            <Button type="button" onClick={onClearCNC}> X </Button>
          </InputGroup>
        </Col>

        <Col md={4}>
          <InputGroup className="mb-3">
            <Form.Control
              id="search"
              as="select"
              value={filterTulus}
              onChange={onFilterTulus}
            >
              <option value="">Filter By Tulus version</option>
                {optionsTulus.sort().map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
            </Form.Control>

            <Button type="button" onClick={onClearTulus}> X </Button>
          </InputGroup>
        </Col>

        <Col md={4}>
          <InputGroup className="mb-3">
            <Form.Control
              id="search"
              as="select"
              value={filterIOT}
              onChange={onFilterIOT}
            >
              <option value="">Filter By IOT version</option>
                {optionsIOT.sort().map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
            </Form.Control>

            <Button type="button" onClick={onClearIOT}> X </Button>
          </InputGroup>
        </Col>

        <Col md={4}>
          <InputGroup className="mb-3">
            <Form.Control
              id="search"
              as="select"
              value={filterTOB}
              onChange={onFilterTOB}
            >
              <option value="">Filter By TOB version</option>
                {optionsTOB.sort().map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
            </Form.Control>

            <Button type="button" onClick={onClearTOB}> X </Button>
          </InputGroup>
        </Col>

      </Row> 

      )}

    </>

      
    );
  }
                            
  

export default FilterComponent;
import React, { useEffect, useState } from "react";
import { Row, Spinner, Alert } from "react-bootstrap";

import { useGlobalState } from '../../components/globalStateProvider.js';
import { machineCustomerOverviewAPI } from '../../api/api_definitions.js';

import TableConnectionCustomer from './TableConnectionCustomer'
import StatusCustomer from './StatusCustomer.js'
import CustomChatBOT from './ChatBot/ChatBot.js'

import loadImage from '../../static/spinner.svg'


const OverviewInfo = ( {user} ) => {

  const customerID = user;

  const [ loading, setLoading ] = useState(true);

  const {
    infoConnectionMachineCustomerID, setInfoConnectionMachineCustomerID,
    tableConnectionMachineCustomerID, setTableConnectionMachineCustomerID,
    tableStatusMachineCustomerID, setTableStatusMachineCustomerID
  } = useGlobalState();

  
  // API GATEWAY RESPONSE MANAGEMENT --> Return list of laser machine family connected to cloud
  useEffect(() => {
    let isMounted = true;
    machineCustomerOverviewAPI(customerID)
      .then((dataAPI) => {
        if (isMounted) {
          if (dataAPI !== null) {
            console.log("Data", dataAPI)
            setInfoConnectionMachineCustomerID(dataAPI)
            setTableConnectionMachineCustomerID(JSON.parse(dataAPI.DF_CONN))
            setTableStatusMachineCustomerID(JSON.parse(dataAPI.DF_EVAL))
            setLoading(false)
          }
        }
      })     
    .catch((error) => {
      console.log(error);
    });
    return () => {
      isMounted = false;
    };
  }, [customerID, setTableConnectionMachineCustomerID, setInfoConnectionMachineCustomerID, setTableStatusMachineCustomerID])


  return (
    <>
      { loading ? (
        <Row className="d-flex flex-column align-items-center justify-content-center text-center mt-5">
              <h3> Loading info... </h3>
              <Spinner animation="border" role="status"></Spinner>
        </Row>
        ) : (
          <>
            { infoConnectionMachineCustomerID && (
              <>
                { !infoConnectionMachineCustomerID.No_connected ? (
                    <Alert variant="success">
                      <h2>CONNECTION STATUS</h2>
                      <h4>All machines are connected and transmit data to cloud</h4>
                    </Alert>
                  ) : (
                    <Alert variant="danger">
                        <h2>CONNECTION STATUS</h2>
                        <h4>Attention, one or more machines are not connected</h4> 
                    </Alert>
                  ) 
                }  

                <Row className="text-center">
                    <TableConnectionCustomer columns={Object.keys(tableConnectionMachineCustomerID)} posts={tableConnectionMachineCustomerID} />
                </Row>

                <div className="my-spacer"></div>

                { !infoConnectionMachineCustomerID.Bad_evaluation ? (
                    <Alert variant="success">
                      <h2>MACHINE STATUS</h2>
                      <h4>All machines work without faults</h4>
                    </Alert>
                  ) : (
                    <Alert variant="danger">
                        <h2>MACHINE STATUS</h2>
                        <h4>Attention, one or more machines have faults</h4>
                    </Alert>
                  ) 
                }  

                <Row className="text-center mt-4 mb-4">
                    <StatusCustomer posts={tableStatusMachineCustomerID} />
                </Row>

                <CustomChatBOT />

              </>
            )}
          </>
        )         
      }    
    </>
  )
}



export default OverviewInfo;
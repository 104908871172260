import React, {useState} from 'react'

import { Row, Col, Card, ListGroup } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation, faCircleExclamation, faCircleCheck } from '@fortawesome/free-solid-svg-icons';

import ErrorInfo from './CustomerErrorDetails/ErrorInfo';

const TableStatusCustomer = ({ posts }) => {

  const columnThreshold = ["1335-Tip Touch Hardware THR_EXCEEDED", 
                            "1337-Tip Touch Software THR_EXCEEDED",
                            "1374-Check Optics Failed Lisa THR_EXCEEDED",
                            "27-LPM Plasma Detect Lisa THR_EXCEEDED",
                            "310-Head collision protection tripped THR_EXCEEDED",
                            "7502-Lens protection glass missing THR_EXCEEDED",
                            "7503-Head Lens is not present THR_EXCEEDED",
                            "7510-Anomaly increase in sensor nozzle temperature (variation) THR_EXCEEDED",
                            "7511-Anomaly increase in Sensor nozzle temperature (threshold) THR_EXCEEDED",
                            "790-Lanny Gas Input pressure Too LOW THR_EXCEEDED",
                            "9047-Scattering Over Limit THR_EXCEEDED",
                            "9053-Anomalous increase in pressure inside the collimator THR_EXCEEDED",
                            "3000-Laser generic error THR_EXCEEDED"
                          ]

  const errorThreshold = ["Excessive number of Tip Touch HW detected",
                          "Excessive number of Tip Touch SW detected",
                          "Excessive number of Check Optics failed' detected",
                          "LPM Plasma has occured too many times",
                          "Excessive number of Head collision detected",
                          "Glass protection cover has been opened too many times",
                          "Lens protection cover has been opened too many times",
                          "The temperature of the nozzle is increasing too fast",
                          "The temperature of the nozzle is too high",
                          "Gas Lanny problems detected",
                          "The scattering inside the collimator is too high",
                          "The pressure inside the collimator is too high",
                          "Excessive number of problems with Laser detected" 
                        ]

  const [showModal, setShowModal]           = useState(false);
  const [modalContent, setModalContent]     = useState('');
  const [errorToAnalyze, setErrorToAnalyze] = useState('');
  const [labelToAnalyze, setLabelToAnalyze] = useState('');
  const [snMachineSelectedCustomer, setSnMachineSelectedCustomer] = useState('');

  // Get time range (previous 30 days)
  const getFormattedDate = (date) => {
    const year = date.getFullYear(); // Full year (e.g., 2024)
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month (01 to 12)
    const day = String(date.getDate()).padStart(2, '0'); // Day (01 to 31)
  
    return `${year}-${month}-${day}`;
  };

  const getDateMinusDays = (days) => {
    const now = new Date();
    const pastDate = new Date(now);
    pastDate.setDate(now.getDate() - days);
    return getFormattedDate(pastDate);
  };

  // Prepare data in format to use ErrorDriverTrendPlot API
  const handleShowModal = (content, errorToAnalyze_label, customerName, snMachine) => {
    const argument = 'CRITICO - ' + customerName + ' - ' + snMachine + ' - ' +  getDateMinusDays(30) + '/' + getDateMinusDays(0); 
    setErrorToAnalyze(errorToAnalyze_label)
    setLabelToAnalyze(argument)
    setSnMachineSelectedCustomer(snMachine)
    setModalContent(content);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  // Prepare data to allow to split the machines of the customer
  let rawManipulation = Object.keys(posts.Customer).map(key => {
    const row = {};
    for (const column in posts) {
      row[column] = posts[column][key];
    }
    return row;
  });

  // Return layout
  return (
    <>
      {showModal && 
        <ErrorInfo show={showModal} 
                    handleClose={handleCloseModal} 
                    content={modalContent}  
                    errorToAnalyze={errorToAnalyze} 
                    labelToAnalyze={labelToAnalyze}
                    snMachineSelectedCustomer={snMachineSelectedCustomer}
        />
      }   

      <Row> 
        {rawManipulation.map((item, idx) => (
          <Col key={idx} md={6} className="mb-3">
            <Card bg='light'>
              <Card.Body>
                <Card.Title className="mb-3 mt-3">Machine: {item.snMachine}</Card.Title>

                { item.Probability_0 < 0.25 && (
                    <>
                      <Card.Subtitle className="mb-3 mt-3" style={{color: "#01412e",}} ><h4>PERFECT</h4></Card.Subtitle>
                      <Card.Subtitle className="mb-5">
                        <FontAwesomeIcon icon={faCircleCheck} size="2xl" style={{ color:"#01412e" }}/>
                      </Card.Subtitle>
                    </>
                )}

                { item.Probability_0 >= 0.25 && item.Probability_0 < 0.5 && (
                    <>
                      <Card.Subtitle className="mb-3 mt-3" style={{color: "#01412e",}}><h4>GOOD</h4></Card.Subtitle>
                      <Card.Subtitle className="mb-5">
                      <FontAwesomeIcon icon={faCircleCheck} size="2xl" style={{color: "#01412e",}} />
                      </Card.Subtitle>
                    </>
                )} 

                { item.Probability_0 >= 0.5 && item.Probability_0 < 0.75 && (
                    <>
                      <Card.Subtitle className="mb-3 mt-3" style={{color: "#8a2c25",}}><h4>WARNING</h4></Card.Subtitle>
                      <Card.Subtitle className="mb-5">
                      <FontAwesomeIcon icon={faCircleExclamation} beat size="2xl" style={{color: "#FFD43B",}} />
                      </Card.Subtitle>
                    </>
                )} 


                { item.Probability_0 >= 0.75 && (
                    <>
                      <Card.Subtitle className="mb-3 mt-3" style={{color: "#8a2c25",}} ><h4>CRITICAL</h4></Card.Subtitle>
                      <Card.Subtitle className="mb-5">
                        <FontAwesomeIcon icon={faTriangleExclamation} beat size="2xl" style={{ color:"#FFD43B" }}/>
                      </Card.Subtitle>
                    </>
                )}


                {columnThreshold.map((col, index) => {
                  if (item[col] === 1 && item.Probability_0 >= 0.5)  {
                    return (
                      <ListGroup key={col} variant="flush">
                        <ListGroup.Item onClick={() => handleShowModal(`${errorThreshold[index]}`, `${columnThreshold[index]}`, `${item.Customer}`, `${item.snMachine}`)}>
                          {errorThreshold[index]}
                        </ListGroup.Item>
                      </ListGroup>
                    );
                  }
                  return null;
                })}

              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

    </>
  );
    
}

export default TableStatusCustomer
import React from "react";
import { Row, Container } from "react-bootstrap";

import ProductionForm from "./ProductionForm.js";
import ProductionStartAnalysis from "./ProductionStartAnalysis.js";


// MAIN PAGE
function AnalisiProduzionePage() {


    return (
        <div>
            <Container>
                <Row className="text-center mt-4 mb-4">
                    <h2>Analisi produzione</h2>
                    <p className="mt-1">
                        Questa sezione presenta una panoramica della produzione complessiva dei diversi clienti e la sua distribuzione in base a vari parametri, come nozzle, gas e spessore.
                    </p>
                </Row>

                <ProductionForm />

                <ProductionStartAnalysis />

            </Container>
        </div>
    );
}

export default AnalisiProduzionePage;
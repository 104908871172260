import React from "react";
import { Row, Form } from "react-bootstrap";

import { useGlobalState } from '../../components/globalStateProvider';


const ProductionSnMachineListCustomer = () => {
    
  const { snMachineProductionCustomer, setSnMachineProductionCustomer, listSNMachinesProductionCustomer } = useGlobalState();

  return (
    <Row className="text-center mt-4 mb-4">
      <Form.Select
        aria-label="Default select example"
        as="select"
        value={snMachineProductionCustomer}
        onChange={e => {
          if (e.target.value !== "") {
            setSnMachineProductionCustomer(e.target.value);
          }
        }}>
        <option value="" disabled>Seleziona una matricola macchina</option>
        {listSNMachinesProductionCustomer.map((item, index) => (
          <option value={item} key={index}>{item}</option>
        ))}
      </Form.Select>
    </Row>
  );
};

export default ProductionSnMachineListCustomer;
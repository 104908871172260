import React, {useState, useEffect} from "react";
import Plot from 'react-plotly.js';
import { Form, Row, Col, Spinner } from 'react-bootstrap';
import { useGlobalState } from '../../../components/globalStateProvider';


const PlotComponentErrorDriver = ({ onBarClick }) => {
    
  const { plotData } = useGlobalState();

  const layoutPlot = { barmode: 'group',
                      width: 1400, 
                      height: 1080,  
                      showlegend: true,
                      margin: {
                        b: 400, // Bottom margin
                        t: 100, // Top margin
                        pad: 4, // Padding between plot and axis labels
                      },
                      bargap: 0.5, // Adjust the gap between bars
                      bargroupgap: 0.05, // Adjust the gap between groups of bars
                      xaxis: {
                        'tickangle' : 90
                      },     
                      yaxis: {
                        'tick0': 0, 
                        'dtick': 2,
                        'gridwidth': 1, 
                        'gridcolor': 'gray',
                        'showgrid': true
                      },
                      title: {
                        'x':0.5,
                        'xanchor': 'center',
                        'yanchor': 'top'
                      },
                      legend: {
                        orientation: 'h',
                        x: 0.5,
                        y: 1.3,
                        xanchor: 'center',
                        yanchor: 'top'
                      }
                    }

    // Click on bar to get coordinate of the plot (label and value)                
    const handleClick = (event) => {
      const { points } = event;
      if (points.length > 0) {
        const point = points[0];
        onBarClick(point.x, point.data.name);
        }
    };


    // Hide/visualize error in the plot
    const [data, setData] = useState([]);
    const [visibility, setVisibility] = useState({});

    useEffect(() => {
      if (plotData && plotData.length > 0) {
        // Initialize visibility when plotData is available
        const initialVisibility = plotData[0].x.reduce((acc, xValue) => {
          acc[xValue] = true;
          return acc;
        }, {});
        setVisibility(initialVisibility);

        // Update data based on visibility
        const newData = plotData.map(trace => ({
          ...trace,
          x: trace.x.filter(xValue => initialVisibility[xValue]),
          y: trace.y.filter((_, index) => initialVisibility[trace.x[index]]),
        }));
        setData(newData);
      }
    }, [plotData]);

    const handleCheckboxChange = (xValue) => {
      setVisibility(prevVisibility => {
        const updatedVisibility = {
          ...prevVisibility,
          [xValue]: !prevVisibility[xValue],
        };

        // Update data based on updated visibility
        const newData = plotData.map(trace => ({
          ...trace,
          x: trace.x.filter(xValue => updatedVisibility[xValue]),
          y: trace.y.filter((_, index) => updatedVisibility[trace.x[index]]),
        }));
        setData(newData);

        return updatedVisibility;
      });
    };


    // Display a loading spinner or message until plotData is available
    if (!plotData || plotData.length === 0) {
      return <Spinner animation="border" role="status"><span className="sr-only"></span></Spinner>;
    }

  // Return layout
  return (
    <>
      <Plot data={data} layout={layoutPlot} onClick={handleClick} />

      <Row className="mt-5 mb-3"><h3>Mostra/Nascondi Errore:</h3></Row>

      <Row className="text-start">
        
        {plotData[0].x.map((xValue, index) => (
          <Col key={index} md={12}>
            <Form.Check
              type="checkbox"
              label={xValue}
              checked={visibility[xValue]}
              onChange={() => handleCheckboxChange(xValue)}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default PlotComponentErrorDriver;
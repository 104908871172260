import React, {useState} from 'react'

import DataTable from 'react-data-table-component';

import FilterComponent from './DataTableComponents/FilterComponent'
import ColumnVisibilityManager from './DataTableComponents/ColumnVisibilityManager';
import ExportComponent, { downloadCSV } from './DataTableComponents/ExportComponent';
import { prepareData } from './DataTableComponents/DataUtils';


const MachineTableConnected = ({ columns, posts }) => {

  // Hide/visualize columns
  const startColumnsHidden = ['ID', 
                              'CADID', 
                              'customerID', 
                              'deviceID', 
                              'deviceName',
                              'deviceSize', 
                              'versionNumber',
                              'created',
                              'Days_passed'
                            ]

  const [hiddenColumns, setHiddenColumns] = useState(startColumnsHidden);

  const toggleColumnVisibility = (columnName) => {
    if (hiddenColumns.includes(columnName)) {
      setHiddenColumns(hiddenColumns.filter((col) => col !== columnName));
    } else {
      setHiddenColumns([...hiddenColumns, columnName]);
    }
  };

  // Prepare data
  const { columnManipulation, rawManipulation } = React.useMemo(
    () => prepareData(posts, columns, hiddenColumns),
    [posts, columns, hiddenColumns]
  );

  // Filter condition
  const [filterCustomer, setFilterCustomer] = useState('');
  const [filterPLC, setFilterPLC]           = useState('');
  const [filterCNC, setFilterCNC]           = useState('');
  const [filterTulus, setFilterTulus]       = useState('');
  const [filterIOT, setFilterIOT]           = useState('');
  const [filterTOB, setFilterTOB]           = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

	const filteredItems = rawManipulation.filter(item => 
    item.customer && item.customer.toLowerCase().includes(filterCustomer.toLowerCase()) &&
    item.PLC_GCode_version && item.PLC_GCode_version.toLowerCase().includes(filterPLC.toLowerCase()) &&
    item.CNC_version && item.CNC_version.toLowerCase().includes(filterCNC.toLowerCase()) &&
    item.Tulus_version && item.Tulus_version.toLowerCase().includes(filterTulus.toLowerCase())  &&
    item.IoT_version && item.IoT_version.toLowerCase().includes(filterIOT.toLowerCase()) &&
    item.TOB_version && item.TOB_version.toLowerCase().includes(filterTOB.toLowerCase())
	);

	const subHeaderComponentMemo = React.useMemo(() => {
    const PLC_GCode_version = [...new Set(rawManipulation.map(item => item.PLC_GCode_version).filter(option => option !== null))];
    const CNC_version = [...new Set(rawManipulation.map(item => item.CNC_version).filter(option => option !== null))];
    const Tulus_version = [...new Set(rawManipulation.map(item => item.Tulus_version).filter(option => option !== null))];
    const IoT_version = [...new Set(rawManipulation.map(item => item.IoT_version).filter(option => option !== null))];
    const TOB_version = [...new Set(rawManipulation.map(item => item.TOB_version).filter(option => option !== null))];

    const handleClearCustomer = () => {
      if (filterCustomer) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterCustomer('');
      }
    };
    const handleClearPLC = () => {
      if (filterPLC) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterPLC('');

      }
    };
    const handleClearCNC = () => {
      if (filterCNC) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterCNC('');
      }
    };
    const handleClearTulus = () => {
      if (filterTulus) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterTulus('');
      }
    };
    const handleClearIOT = () => {
      if (filterIOT) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterIOT('');

      }
    };
    const handleClearTOB = () => {
      if (filterTOB) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterTOB('');
      }
    };

    return (
      <FilterComponent  filterCustomer={filterCustomer} 
                        onFilterCustomer={e => setFilterCustomer(e.target.value)} 
                        filterPLC={filterPLC} 
                        onFilterPLC={e => setFilterPLC(e.target.value)} 
                        filterCNC={filterCNC} 
                        onFilterCNC={e => setFilterCNC(e.target.value)} 
                        filterTulus={filterTulus} 
                        onFilterTulus={e => setFilterTulus(e.target.value)} 
                        filterIOT={filterIOT} 
                        onFilterIOT={e => setFilterIOT(e.target.value)} 
                        filterTOB={filterTOB} 
                        onFilterTOB={e => setFilterTOB(e.target.value)} 
                        optionsPLC={PLC_GCode_version}
                        optionsCNC={CNC_version}
                        optionsTulus={Tulus_version}
                        optionsIOT={IoT_version}
                        optionsTOB={TOB_version}
                        onClearCustomer={handleClearCustomer} 
                        onClearPLC={handleClearPLC} 
                        onClearCNC={handleClearCNC} 
                        onClearTulus={handleClearTulus} 
                        onClearIOT={handleClearIOT} 
                        onClearTOB={handleClearTOB} 
                      />
    );
	}, [filterCustomer, filterPLC, filterCNC, filterTulus, filterIOT, filterTOB, rawManipulation, resetPaginationToggle]);


  // Conditional color logic
  const conditionalRowStyles = [
    {
      when: row => row.Days_passed > 5,
      style: {
        backgroundColor: 'rgba(250, 218, 94, 0.9)',
        color: 'black',
      },
    },
    {
      when: row => row.Days_passed > 10,
      style: {
        backgroundColor: 'rgba(255, 165, 0, 0.9)',
        color: 'black',
      },
    },
    {
      when: row => row.Days_passed > 15,
      style: {
        backgroundColor: 'rgba(150, 40, 27, 0.9)',
        color: 'white',
      },
    },
    {
      when: row => row.Days_passed < 0,
      style: {
        backgroundColor: 'rgba(150, 40, 27, 0.9)',
        color: 'white',
      },
    },
  ];

  // Custom table style
  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold',
      },
    }   
  };

  // Return table structure
  return (
    <>
      <ColumnVisibilityManager
        columns={columnManipulation}
        hiddenColumns={hiddenColumns}
        toggleColumnVisibility={toggleColumnVisibility}
      />
    
      <DataTable
          columns = {columnManipulation.filter((col) => !col.omit)}
          data = {filteredItems}
          pagination
          actions={<ExportComponent onExport={() => downloadCSV(rawManipulation)} />}
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          conditionalRowStyles={conditionalRowStyles}
          className='disable-sticky'
          customStyles={customStyles}
      />
    </>
  )
    
}

export default MachineTableConnected
import React, { useState, useEffect } from "react";
import { Row, Col, Button, Modal, Spinner } from "react-bootstrap";

import { useGlobalState } from '../../components/globalStateProvider';
import { machineProductionCustomerPlotAPI } from '../../api/api_definitions.js';

import ProductionPlotCustomer from "./PlotComponents/ProductionPlotCustomer.js";
import ProductionErrorCustomer from "./PlotComponents/ProductionErrorCustomer.js"
import ProductionErrorProdCustomer from "./PlotComponents/ProductionErrorProdCustomer.js"


const ProductionStartAnalysisCustomer = () => {
    
    const { startDateProductionCustomer, endDateProductionCustomer, 
            machineFamilyProductionCustomer, snMachineProductionCustomer, customerNameProductionCustomer,
            infoPlotProductionCustomer, setInfoPlotProductionCustomer
         } = useGlobalState();

    // Modal window management
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [loading, setLoading] = useState(true)
    const [startAnalysis, setStartAnalysis] = useState(false)

    // Disable/enable button conditions
    const disableButton = startDateProductionCustomer === null || endDateProductionCustomer === null || snMachineProductionCustomer === "na" || snMachineProductionCustomer === "";

    // Reset the plot by disabling start analysis variable
    useEffect(() => {
        setStartAnalysis(false);
        setInfoPlotProductionCustomer(null)
    }, [machineFamilyProductionCustomer, customerNameProductionCustomer, snMachineProductionCustomer, startDateProductionCustomer]); 
        

    // Call function to plot data
    const startProductionPlot = () => {
        setStartAnalysis(true)
        setLoading(true)
        // Manage time range in the correct format
        let year = startDateProductionCustomer.getFullYear();
        let month = String(startDateProductionCustomer.getMonth() + 1).padStart(2, '0'); // Adding 1 to month because it's zero-based
        let day = String(startDateProductionCustomer.getDate()).padStart(2, '0');
        let startDateApi = `${year}-${month}-${day}`;

        year = endDateProductionCustomer.getFullYear();
        month = String(endDateProductionCustomer.getMonth() + 1).padStart(2, '0'); // Adding 1 to month because it's zero-based
        day = String(endDateProductionCustomer.getDate()).padStart(2, '0');
        let endDateApi = `${year}-${month}-${day}`;

        // API GATEWAY RESPONSE MANAGEMENT --> Return error driver plot
        machineProductionCustomerPlotAPI(machineFamilyProductionCustomer, customerNameProductionCustomer, snMachineProductionCustomer, startDateApi, endDateApi)
        .then((dataAPI) => {
            if (dataAPI !== null) {
                console.log("Data machineProductionCustomerPlotAPI", dataAPI)
                setLoading(false)
                setInfoPlotProductionCustomer(dataAPI)
                if (dataAPI.empty_df) {
                    setShow(true)
                }
            }      
            else {
                setShow(true)
            }
        })
        .catch((error) => {
            console.log(error)
            setStartAnalysis(false);
            setLoading(true)
            setShow(true)
        });
    };


    // Return loading layout
    if (startAnalysis & loading) {
        return (
            <>
                <Row className="text-center mt-4 mb-4">
                    <Col md={{ span: 4, offset: 4 }}>
                        <p>Caricamento info produzione cliente...</p>
                        <Spinner animation="border" role="status"></Spinner>
                    </Col>
                </Row>
            </>
        )
    }
    
    // Return layout
    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>AVVISO</Modal.Title>
                </Modal.Header>
                <Modal.Body> Nessun o solo alcuni dati sono disponibili </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Chiudi</Button>
                </Modal.Footer>
            </Modal>

            { !disableButton &&
                <Row className="text-center mt-4 mb-4">
                    <Col md={{ span: 4, offset: 4 }}>
                        <Button size='lg' onClick={startProductionPlot}> Inizia Analisi </Button>
                    </Col>
                </Row>
            }


            {infoPlotProductionCustomer && infoPlotProductionCustomer.total_time !== 0 &&        
                <h4 className="text-center mt-5 mb-5">Tempo totale di lavoro: {infoPlotProductionCustomer.total_time} h</h4>
            }

            { startAnalysis && infoPlotProductionCustomer !== null && !infoPlotProductionCustomer.empty_df && 
                <div>
                    <Row className="mt-5 mb-5">
                        <ProductionPlotCustomer values={infoPlotProductionCustomer.nozzle_time} labels={infoPlotProductionCustomer.nozzle_type} title='Nozzle' />
                        <ProductionErrorCustomer results={infoPlotProductionCustomer.error_dict_nozzle} /> 
                        <ProductionErrorProdCustomer results={infoPlotProductionCustomer.nozzle_dict_error} />
                    </Row>

                    <Row className="mb-5">
                        <ProductionPlotCustomer values={infoPlotProductionCustomer.gas_time} labels={infoPlotProductionCustomer.gas_type} title='Gas' />
                        <ProductionErrorCustomer results={infoPlotProductionCustomer.error_dict_gas} />
                        <ProductionErrorProdCustomer results={infoPlotProductionCustomer.gas_dict_error} />
                    </Row>

                    <Row className="mb-5">
                        <ProductionPlotCustomer values={infoPlotProductionCustomer.tool_time} labels={infoPlotProductionCustomer.tool_type} title='Nozzle + Gas' />
                        <ProductionErrorCustomer results={infoPlotProductionCustomer.error_dict_tool} />
                        <ProductionErrorProdCustomer results={infoPlotProductionCustomer.tool_dict_error} />
                    </Row>

                    <Row className="mb-5">
                        <ProductionPlotCustomer values={infoPlotProductionCustomer.thickness_time} labels={infoPlotProductionCustomer.thickness_type} title='Thickness' />
                        <ProductionErrorCustomer results={infoPlotProductionCustomer.error_dict_thickness} />
                        <ProductionErrorProdCustomer results={infoPlotProductionCustomer.thickness_dict_error} />
                    </Row>
                </div>
            }
        </>
    );  
};
    

export default ProductionStartAnalysisCustomer;
import React, { useState } from "react";

import PlotComponentErrorDriver from './PlotComponentErrorDriver.js';
import PlotComponentErrorTrend from './PlotComponentErrorTrend.js'

import { machineAnalysisPlotErrorTrendAPI } from '../../../api/api_definitions.js';


const PlotComponent = () => {
    
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true)
  const [modalData, setModalData] = useState(null);
  const [errorString, setErrorString] = useState("")
  const [customerString, setCustomerString] = useState("")


  // API GATEWAY RESPONSE MANAGEMENT --> Return error driver trend plot
  const handleBarClick = (error_type, label) => {
    machineAnalysisPlotErrorTrendAPI(error_type, label)
      .then(setLoading(true))
      .then(setShowModal(true))
      .then((dataAPI) => {
        const DF = JSON.parse(dataAPI.DF)
        const DF2 = JSON.parse(dataAPI.DF2)

        const timeDays = Object.values(DF.time_days).map(t => new Date(t));
        const numberOfErrors = Object.values(DF.number_of_error);
        const runningDurationIncremental = Object.values(DF.runningDurationIncremental);
        const glassOpeningTime = Object.values(DF.glassOpeningTime);

        const plotData = Object.keys(DF2).map(tools => {
          const timestamps = Object.keys(DF2[tools]);
          const values = Object.values(DF2[tools]);
    
          return {
            x: timestamps.map(ts => new Date(Number(ts))),
            y: values,
            type: 'bar',
            name: tools
          };
        });

        const data = [
          {
            x: timeDays,
            y: numberOfErrors,
            type: 'scatter',
            mode: 'lines+markers',
            name: 'Number of Errors',
            yaxis: 'y',
            marker: { color: 'red' },
          },
          {
            x: timeDays,
            y: runningDurationIncremental,
            type: 'scatter',
            mode: 'markers',
            name: 'Running Duration Incremental [hour]',
            yaxis: 'y2',
            marker: { color: 'blue' }
          },
          {
            x: timeDays,
            y: glassOpeningTime,
            type: 'scatter',
            mode: 'lines+markers',
            name: 'Glass Opening Time total [s]',
            yaxis: 'y3',
            marker: { color: 'green' }
          },
          ...plotData
        ];

        setModalData(data);
        setErrorString(error_type)
        setCustomerString(label)
        setLoading(false)

      })
      .catch((error) => {
        setShowModal(false)
        console.log(error);
      });
  };

  // Close Modal window
  const handleClose = () => setShowModal(false);

  // Return layout
  return (
    <>
      <PlotComponentErrorDriver onBarClick={handleBarClick}/>
      <PlotComponentErrorTrend show={showModal} loadingPlot={loading} handleClose={handleClose} data={modalData} error_string={errorString} customer_string={customerString}/>
    </>
  );
};

export default PlotComponent;
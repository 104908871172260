/**
 * The code defines functions to fetch an API key and configure Amplify with the fetched API key for a
 * REST API.
 * @returns The code snippet provided is a JavaScript module that exports two functions: `getApiKey`
 * and `configureAmplifyWithApiKey`, as well as the `Amplify` object.
 */
import { Amplify } from 'aws-amplify';
import { get } from 'aws-amplify/api';
import config from './amplifyconfiguration.json';

Amplify.configure(config);

const getApiKey = async () => {
    try {
      const restOperation = get({
        apiName: "iot-webapp-api",
        path: "/info",
      });
      const { body } = await restOperation.response;
      const json     = await body.json();
      var jsonInfo   = JSON.parse(json.body)
      
      return jsonInfo.API_KEY;
    } 
    catch (error) {
      console.error('Error fetching API key:', error);
      throw error;
    }
  }

const configureAmplifyWithApiKey = async () => {
  const apiKey = await getApiKey();

    Amplify.configure(config, {
      API: {
          REST: {
            headers: () => {
                return { 'X-Api-Key': apiKey };
            }
          }
        }
      });

};

export { configureAmplifyWithApiKey, Amplify };



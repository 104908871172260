export function reverseTable(posts) {
    return posts[0].map((_, colIndex) => posts.map(row => row[colIndex]));
}

/* ---------------------------------------------------- ML ALGORITHM DATA ----------------------------------------------------------------- */
export function prepareData(posts, columns, hiddenColumns) {
    const reversedTable = reverseTable(posts);

    const columnManipulation = columns.map(col => ({
        name: col,
        selector: row => row[col],
        sortable: true,
        filterable: true,
        wrap: true,
        omit: hiddenColumns.includes(col),
        style: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        headerStyle: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    }));


    const rawManipulation = reversedTable.map(raw => raw.reduce((obj, item, index) => {
        obj[columns[index]] = item;
        return obj;
    }, {}));

    // Sort by Probability
    rawManipulation.sort((a, b) => b.Probability_0 - a.Probability_0);

    return { columnManipulation, rawManipulation };

}

export function getColumnValues(data, column) {
    return [...new Set(data.map(item => item[column]))];
  }

/* ---------------------------------------------------- THRESHOLD ALGORITHM DATA ----------------------------------------------------------------- */
export function prepareDataThresholds(posts, columns, hiddenColumns) {

    const reversedTable = reverseTable(posts);

    const LedIndicator = ({ value }) => {
        const ledStyle = {
          width: '15px',
          height: '15px',
          borderRadius: '50%',
          display: 'inline-block',
          backgroundColor: value === 0 ? 'green' : value === 1 ? 'red' : 'transparent', // if 0 the value is under the threshold
        };
        return <span style={ledStyle}></span>;
      };

    const columnManipulation = columns.map(col => ({
        name: col,
        selector: row => row[col],
        cell: col.includes(' THR_EXC') ? row => <LedIndicator value={row[col]} /> : undefined,
        sortable: true,
        filterable: true,
        wrap: true,
        omit: hiddenColumns.includes(col),
        style: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        headerStyle: {
            maxWidth: '100px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    }));

    const rawManipulation = reversedTable.map(raw => raw.reduce((obj, item, index) => {
        obj[columns[index]] = item;
        return obj;
    }, {}));

    // Sort by probability
    rawManipulation.sort((a, b) => b.ThresholdsExceeded - a.ThresholdsExceeded);

    return { columnManipulation, rawManipulation };

}
/**
 * The `DateRangeSelector` component in React allows users to select a date range using a date picker
 * with specific configurations.
 * @returns The `DateRangeSelector` component is being returned. It consists of a date range selector
 * UI component using the `react-datepicker` library within a `Row` component from `react-bootstrap`.
 * The component allows the user to select a date range and updates the global state with the selected
 * start and end dates.
 */
import React from "react";
import { Row } from "react-bootstrap";

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { useGlobalState } from '../../components/globalStateProvider';

const DateRangeSelector = () => {
    
  const { startDate, endDate, setDateRange } = useGlobalState();

  return (
    <>
      <Row className="text-center mt-4 mb-1">
        <p>Seleziona il periodo:</p>
      </Row>
      <Row className="text-center mt-1 mb-4">
        <DatePicker
          showIcon
          selectsRange
          startDate={startDate}
          endDate={endDate}
          onChange={(update) => { setDateRange(update); }}
          isClearable
          withPortal
          dateFormat="yyyy/MM/dd"
          maxDate={new Date()}
          allowSameDay
          showWeekNumbers
          calendarStartDay={1}
        />
      </Row>
    </>
  );
};

export default DateRangeSelector;
import React, {useState} from 'react'
import DataTable from 'react-data-table-component';

import ColumnVisibilityManager from './DataTableComponents/ColumnVisibilityManager'
import FilterComponent from './DataTableComponents/FilterComponent';
import ExportComponent, { downloadCSV } from './DataTableComponents/ExportComponent';
import { prepareDataThresholds } from './DataTableComponents/DataUtils';


const MachineListInfoML = ({ columns, posts }) => {

  const [filterCustomerText, setFilterCustomerText]   = useState('');
  const [filterSnMachineText, setFilterSnMachineText] = useState('');
  const [filterLaserText, setFilterLaserText]         = useState('');
  const [filterHeadText, setFilterHeadText]           = useState('');

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    // List of columns to hide
    const startColumnsHidden = ['customerID', 'Laser']
    const [hiddenColumns, setHiddenColumns] = useState(startColumnsHidden);

    // Prepare data
    const { columnManipulation, rawManipulation } = React.useMemo(
      () => prepareDataThresholds(posts, columns, hiddenColumns),
      [posts, columns, hiddenColumns]
    );

    const toggleColumnVisibility = columnName => {
      setHiddenColumns(prev => 
        prev.includes(columnName) ? prev.filter(col => col !== columnName) : [...prev, columnName]
      );
    };

    // Filter data
    const filteredItems = rawManipulation.filter(item => {
      return (
        item.Customer && item.Customer.toLowerCase().includes(filterCustomerText.toLowerCase()) &&
        item.snMachine && item.snMachine.toLowerCase().includes(filterSnMachineText.toLowerCase()) &&
        item.Laser && item.Laser.toLowerCase().includes(filterLaserText.toLowerCase()) &&
        item.Head && item.Head.toLowerCase().includes(filterHeadText.toLowerCase()) 
      );
    });

    const subHeaderComponentMemo = React.useMemo(() => {
      const snMachineOptions = [...new Set(rawManipulation.map(item => item.snMachine).filter(option => option !== null))];
      const laserTypeOptions = [...new Set(rawManipulation.map(item => item.Laser).filter(option => option !== null))];
      const headTypeOptions = [...new Set(rawManipulation.map(item => item.Head).filter(option => option !== null))];

      const handleClearCustomer = () => {
        if (filterCustomerText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterCustomerText('');
        }
      };
      const handleClearSnMachine = () => {
        if (filterSnMachineText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterSnMachineText('');
        }
      };
      const handleClearLaser = () => {
        if (filterLaserText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterLaserText('');
        }
      };
      const handleClearHead = () => {
        if (filterHeadText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterHeadText('')
        }
      };
  
      return (
        <FilterComponent onFilterCustomer={e => setFilterCustomerText(e.target.value)} 
                          filterCustomer={filterCustomerText} 
                          onFilterSnMachine={e => setFilterSnMachineText(e.target.value)} 
                          filterSnMachine={filterSnMachineText}
                          onFilterLaser={e => setFilterLaserText(e.target.value)} 
                          filterLaser={filterLaserText}
                          onFilterHead={e => setFilterHeadText(e.target.value)} 
                          filterHead={filterHeadText}
                          snMachineOptions={snMachineOptions}
                          laserTypeOptions={laserTypeOptions}
                          headTypeOptions={headTypeOptions}
                          onClearCustomer={handleClearCustomer} 
                          onClearSnMachine={handleClearSnMachine}
                          onClearLaser={handleClearLaser}
                          onClearHead={handleClearHead}
        />
      );
    }, [filterCustomerText, filterSnMachineText, filterLaserText, filterHeadText, rawManipulation, resetPaginationToggle]);

    // Custom styles for the header
    const customStyle = {
      headCells: {
        style: {
          fontWeight: 'bold',
        },
      }
    };

    // Return layout
    return (
      <>
        <ColumnVisibilityManager
          columns={columnManipulation}
          hiddenColumns={hiddenColumns}
          toggleColumnVisibility={toggleColumnVisibility}
        />

        <DataTable
            columns = {columnManipulation.filter((col) => !col.omit)}
            data = {filteredItems}
            direction="auto"
            pagination
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            actions={<ExportComponent onExport={() => downloadCSV(rawManipulation)} />}
            persistTableHead
            customStyles={customStyle}
            dense
            className='enable-sticky'
        />
    </>
  )
     
}

export default MachineListInfoML
/* The above code is a JavaScript module that contains various functions to make API calls to retrieve
data related to machine analysis, error analysis, machine status, sensor signals, production
analysis, and customer overview. */

import { get, post } from 'aws-amplify/api';
import { configureAmplifyWithApiKey } from '../AmplifyConfig';

/* CACHE VARIABLE */
const cacheMachineConnected        = {}

const cacheMachineList             = {}
const cacheMachineSNList           = {}
const cacheMachineStatusML         = {}

const cacheMachineSensor           = {}

const cacheMachineListProductionCustomer   = {}
const cacheMachineSNListProductionCustomer = {}

const cacheMachineCustomerOverview = {}


/* ------------------------------------------------------------------------- ANALISI CONNESSIONE MACCHINE ------------------------------------------------------------------------- */

export const machineOnFleetTableAPI = async (machine_type) => {

  if (cacheMachineConnected[machine_type]) {
    console.log("Retrieving from cache");
    return cacheMachineConnected[machine_type];
  }
  
  const headers = {"machine_type": machine_type}

  if (Number(machine_type) === 0) {
    return null
  }

  try {
    console.log("EXECUTING machineOnFleetTableAPI")

    const restOperation = get({
      apiName: "iot-webapp-api",
      path: "/machine-connection/" + machine_type,
      headers: {headers}
    });

    const { body } = await restOperation.response;
    
    const json = await body.json();

    var jsonTable = JSON.parse(json.body)

    cacheMachineConnected[machine_type] = jsonTable;

    return jsonTable;
  }
  catch (error) {
    console.error("Error fetching machineOnFleetTableAPI:", error);
    throw error; 
    
  }
}


/* --------------------------------------------------------------------------- ANALISI STATO MACCHINE --------------------------------------------------------------------------- */

export const machineStatusMLAPI = async (machine_type) => {

  if (cacheMachineStatusML[machine_type]) {
    console.log("Retrieving from cache");
    return cacheMachineStatusML[machine_type];
  }
  
  const headers = {"machine_type": machine_type}

  if (Number(machine_type) === 0) {
     return null
  }

  try {
    console.log("EXECUTING machineStatusMLAPI")

    const restOperation = get({
      apiName: "iot-webapp-api",
      path: "/machine-learning/analysis/" + machine_type,
      headers: {headers}
    });
  
    const { body } = await restOperation.response;
    const json = await body.json();
    var jsonTable = JSON.parse(json.body)
    cacheMachineStatusML[machine_type] = jsonTable;
  
    return jsonTable;
  } 
  catch (error) {
    console.error("Error fetching machineStatusMLAPI:", error);
    throw error; 
  }

}


/* ------------------------------------------------------------------------------- ANALISI ERRORE DRIVER ------------------------------------------------------------------------- */

export const machineListSelectedAPI = async (machine_type) => {

  if (cacheMachineList[machine_type]) {
    console.log("Retrieving from cache");
    return cacheMachineList[machine_type];
  }

  const headers = {"machine_type": machine_type};

  if (Number(machine_type) === 0) {
    return null
  }

  try {
    console.log("EXECUTING machineListSelectedAPI")

    const restOperation = get({
      apiName: "iot-webapp-api",
      path: "/analysis/" + machine_type,
      headers: {headers}
    });

    const { body } = await restOperation.response;
    const json = await body.json();

    var jsonList = JSON.parse(json.body)

    cacheMachineList[machine_type] = jsonList

    return jsonList;
  }
  catch (error) {
    console.error("Error fetching machineListSelectedAPI:", error);
    throw error; 
  }
}



export const machineSNListSelectedAPI = async (machine_type, customer_type) => {

  const cacheKey = `${machine_type}_${customer_type}`;

  if (cacheMachineSNList[cacheKey]) {
    console.log("Retrieving from cache");
    return cacheMachineSNList[cacheKey];
  }

  const headers  = {"customer_type": customer_type, "machine_type": machine_type};

  if (customer_type === "") {
    return null
  }

  try {
    console.log("EXECUTING machineSNListSelectedAPI")

    const restOperation = get({
      apiName: "iot-webapp-api",
      path: "/analysis/" + machine_type + "/" + customer_type,
      headers: {headers}
    });

    const { body } = await restOperation.response;
    const json = await body.json();
    var jsonList = JSON.parse(json.body)
    cacheMachineSNList[cacheKey] = jsonList

    return jsonList;
  }
  catch (error) {
    console.error("Error fetching machineSNListSelectedAPI:", error);
    throw error; 
  }
}



export const machineAnalysisPlotErrorDriverAPI = async (machine_type, customer_type, snMachine_type, startDate, endDate) => {

  const date_range = startDate + '+' + endDate

  const headers  = {"customer_machine": customer_type, 
                    "machine_type": machine_type,
                    "snMachine_type": snMachine_type,
                    "date_range": date_range
                  };

  if ((snMachine_type === "") || (startDate === null)) {
    return null
  }

  try {
    console.log("EXECUTING machineAnalysisPlotErrorDriverAPI")

    const restOperation = get({
      apiName: "iot-webapp-api",
      path: "/analysis/" + machine_type + "/" + customer_type + "/" + snMachine_type + "/" + date_range,
      headers: {headers}
    });

    const { body } = await restOperation.response;
    const json = await body.json();
    var jsonPlotData = JSON.parse(json.body)

    return jsonPlotData;
  } 
  catch (error) {
    console.error("Error fetching machineAnalysisPlotErrorDriverAPI:", error);
    throw error;
    // return null; 
  }
}


export const machineLearningModelTrainAvgValueAPI = async () => {

  try {
    console.log("EXECUTING machineLearningModelTrainAvgValueAPI")

    const restOperation = get({
      apiName: "iot-webapp-api",
      path: "/machine-learning/info-train-value",
    });
  
    const { body } = await restOperation.response;
    const json = await body.json();
  
    var jsonMLInfoTrain = JSON.parse(json.body)
  
    return jsonMLInfoTrain;

  }
  catch (error) {
    console.error("Error fetching machineLearningModelTrainAvgValueAPI:", error);
    throw error; 
  }
}



export const machineAnalysisPlotErrorTrendAPI = async (error_type, label) => {

  try {
    console.log("EXECUTING machineAnalysisPlotErrorTrendAPI")

    const bodyRequest  = {"error_type": error_type, 
                          "label": label
                        };

    const restOperation = post({
      apiName: "iot-webapp-api",
      path: "/analysis/trend-error",
      options: { body: bodyRequest }
    });

    const { body } = await restOperation.response;
    const json = await body.json();
    const jsonPlotData = JSON.parse(json.body)

    return jsonPlotData;
  } 
  catch (error) {
    console.error("Error fetching machineAnalysisPlotErrorTrendAPI:", error);
    throw error; 
  }
}




/* ------------------------------------------------------------------------- ANALISI SEGNALI SENSORI ------------------------------------------------------------------------- */

export const machineSignalSensorsAPI = async (machine_type) => {

  if (cacheMachineSensor[machine_type]) {
    console.log("Retrieving from cache");
    return cacheMachineSensor[machine_type];
  }
  
  const headers = {"machine_type": machine_type}

  if (Number(machine_type) === 0) {
    return null
  }

  try {
    console.log("EXECUTING machineSignalSensorsAPI")

    const restOperation = get({
      apiName: "iot-webapp-api",
      path: "/sensors-analysis/" + machine_type,
      headers: {headers}
    });

    const { body } = await restOperation.response;
    
    const json = await body.json();

    var jsonTable = JSON.parse(json.body)

    cacheMachineSensor[machine_type] = jsonTable;

    return jsonTable;
  }
  catch (error) {
    console.error("Error fetching machineSignalSensorsAPI:", error);
    throw error; 
  }
}



export const machineSignalSensorsAnalysisAPI = async (machine_type, ids,sensor_type) => {

  try {
    console.log("EXECUTING machineSignalSensorsAnalysisAPI")

    const bodyRequest  = {"machine_type": machine_type,
                          "ids": ids, 
                          "sensor_type": sensor_type
                        };

    const restOperation = post({
      apiName: "iot-webapp-api",
      path: "/sensors-analysis/info-sensor",
      options: { body: bodyRequest }
    });

    const { body } = await restOperation.response;
    const json = await body.json();
    const jsonPlotInfo = JSON.parse(json.body)

    return jsonPlotInfo;
  } 
  catch (error) {
    console.error("Error fetching machineSignalSensorsAnalysisAPI:", error);
    throw error; 
  }
}


/* ------------------------------------------------------------------------- ANALISI PRODUZIONE ------------------------------------------------------------------------- */

export const machineProductionMachinesAPI = async (machine_type, head_type, min_laser, max_laser, start_date, end_date) => {

  const bodyRequest  = {"machine_type": machine_type, 
                        "head_type": head_type,
                        "min_laser": min_laser,
                        "max_laser": max_laser,
                        "start_date": start_date,
                        "end_date": end_date
                      };

  console.log("bodyRequest", bodyRequest)

  try {
    console.log("EXECUTING machineProductionMachinesAPI")

    const restOperation = post({
      apiName: "iot-webapp-api",
      path: "/production-analysis",
      options: { body: bodyRequest }
    });

    const { body } = await restOperation.response;
    
    const json = await body.json();

    var jsonTable = JSON.parse(json.body)

    return jsonTable;
  }
  catch (error) {
    console.error("Error fetching machineProductionMachinesAPI:", error);
    throw error; 
  }
}


/* ------------------------------------------------------------------------------- ANALISI PRODUZIONE CLIENTE ------------------------------------------------------------------------- */

export const machineListSelectedProductionCustomerAPI = async (machine_type) => {

  if (cacheMachineListProductionCustomer[machine_type]) {
    console.log("Retrieving from cache");
    return cacheMachineListProductionCustomer[machine_type];
  }

  const headers = {"machine_type": machine_type};

  if (Number(machine_type) === 0) {
    return null
  }

  try {
    console.log("EXECUTING machineListSelectedProductionCustomerAPI")

    const restOperation = get({
      apiName: "iot-webapp-api",
      path: "/production-customer-analysis/" + machine_type,
      headers: {headers}
    });

    const { body } = await restOperation.response;
    const json = await body.json();

    var jsonList = JSON.parse(json.body)

    cacheMachineListProductionCustomer[machine_type] = jsonList

    return jsonList;
  }
  catch (error) {
    console.error("Error fetching machineListSelectedProductionCustomerAPI:", error);
    throw error; 
  }
}



export const machineSNListSelectedProductionCustomerAPI = async (machine_type, customer_type) => {

  const cacheKey = `${machine_type}_${customer_type}`;

  if (cacheMachineSNListProductionCustomer[cacheKey]) {
    console.log("Retrieving from cache");
    return cacheMachineSNListProductionCustomer[cacheKey];
  } 

  const headers  = {"customer_type": customer_type, "machine_type": machine_type};

  if (customer_type === "") {
    return null
  }

  try {
    console.log("EXECUTING machineSNListSelectedProductionCustomerAPI")

    const restOperation = get({
      apiName: "iot-webapp-api",
      path: "/production-customer-analysis/" + machine_type + "/" + customer_type,
      headers: {headers}
    });

    const { body } = await restOperation.response;
    const json = await body.json();
    var jsonList = JSON.parse(json.body)
    cacheMachineSNListProductionCustomer[cacheKey] = jsonList

    return jsonList;
  }
  catch (error) {
    console.error("Error fetching machineSNListSelectedProductionCustomerAPI:", error);
    throw error; 
  }
}



export const machineProductionCustomerPlotAPI = async (machine_type, customer_type, snMachine_type, startDate, endDate) => {

  const date_range = startDate + '+' + endDate

  const headers  = {"customer_machine": customer_type, 
                    "machine_type": machine_type,
                    "snMachine_type": snMachine_type,
                    "date_range": date_range
                  };

  if ((snMachine_type === "") || (startDate === null)) {
    return null
  }

  try {
    console.log("EXECUTING machineProductionCustomerPlotAPI")

    const restOperation = get({
      apiName: "iot-webapp-api",
      path: "/production-customer-analysis/" + machine_type + "/" + customer_type + "/" + snMachine_type + "/" + date_range,
      headers: {headers}
    });

    const { body } = await restOperation.response;
    const json = await body.json();
    var jsonPlotData = JSON.parse(json.body)

    return jsonPlotData;
  } 
  catch (error) {
    console.error("Error fetching machineProductionCustomerPlotAPI:", error);
    throw error;
    // return null; 
  }
}


/* --------------------------------------------------------------------------- ANALISI OVERVIEW CLIENTE --------------------------------------------------------------------------- */

export const machineCustomerOverviewAPI = async (customer_id) => {

  if (cacheMachineCustomerOverview[customer_id]) {
    console.log("Retrieving from cache");
    return cacheMachineCustomerOverview[customer_id];
  }
  
  const headers = {
    "customer_id": customer_id
  }

  if (customer_id === "" || !customer_id) {
    return null
  }

  try {

    await configureAmplifyWithApiKey();

    console.log("EXECUTING machineCustomerOverviewAPI")

    const restOperation = get({
      apiName: "iot-webapp-api",
      path: "/customer-analysis/" + customer_id,
      headers: headers
    });

    const { body } = await restOperation.response;
      
    const json = await body.json();

    var jsonTable = JSON.parse(json.body)

    cacheMachineCustomerOverview[customer_id] = jsonTable;

    return jsonTable;
  }
  catch (error) {
    console.error("Error fetching machineCustomerOverviewAPI:", error);
    throw error; 
  }

}


export const machineAnalysisPlotErrorTrendCustomerAPI = async (error_type, label) => {

  try {
    console.log("EXECUTING machineAnalysisPlotErrorTrendCustomerAPI")

    const bodyRequest  = {"error_type": error_type, 
                          "label": label
                        };

    const restOperation = post({
      apiName: "iot-webapp-api",
      path: "/customer-analysis/trend-error",
      options: { body: bodyRequest }
    });

    const { body } = await restOperation.response;
    const json = await body.json();
    const jsonPlotData = JSON.parse(json.body)

    return jsonPlotData;
  } 
  catch (error) {
    console.error("Error fetching machineAnalysisPlotErrorTrendCustomerAPI:", error);
    throw error; 
  }
}


/* --------------------------------------------------------------------------- CREATE NEW USER --------------------------------------------------------------------------- */

export const createNewUserAPI = async ({username, group}) => {

  try {
    console.log("EXECUTING createNewUserAPI", username)

    const bodyRequest  = {"username": username,
                          "group_name": group
                        };

    const restOperation = post({
      apiName: "iot-webapp-api",
      path: "/user-management/create-new-user",
      options: { body: bodyRequest }
    });

    const { body } = await restOperation.response;
    const json = await body.json();
    const jsonPlotInfo = JSON.parse(json.body)

    return jsonPlotInfo;
  } 
  catch (error) {
    console.error("Error fetching createNewUserAPI:", error);
    throw error; 
  }

}
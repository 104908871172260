import React from "react";
import { Row, Col, Form } from "react-bootstrap";

import { useGlobalState } from '../../components/globalStateProvider.js';

import ProductionDateRangeSelector from "./ProductionDateRangeSelector.js";

// MAIN PAGE
function ProductionForm() {

    const { machineFamilyProduction, setMachineFamilyProduction,
            headTypeProduction, setHeadTypeProduction,
            minLaserPowerProduction, setMinLaserPowerProduction,
            maxLaserPowerProduction, setMaxLaserPowerProduction
        } = useGlobalState();


    return (
        <div>
            <Form>
                <Row className="text-center">

                    <Col md={{ span: 4, offset: 2 }}>
                        <Form.Label className="mt-5 mb-1"><strong>Seleziona la famiglia macchina</strong></Form.Label> {/* Display the current value */}
                        <Form.Select className="mb-3"
                            value={machineFamilyProduction}
                            onChange={(e) => {
                                setMachineFamilyProduction(e.target.value)                                   
                            }}
                        >
                            <option value="0" disabled>--</option>
                            <option value="1">Laser Genius +</option>
                            <option value="2">Platino Linear</option>
                        </Form.Select>

                        <Form.Label className="mt-4 mb-1"><strong>Seleziona la tipologia testa</strong></Form.Label> {/* Display the current value */}
                        <Form.Select
                            className="mb-3"
                            value={headTypeProduction}
                            onChange={(e) => {
                                setHeadTypeProduction(e.target.value);
                            }}
                        >
                            <option value="0">Includi tutte le tipologia testa</option>
                            <option value="1">FSC3</option>
                            <option value="2">Precitec</option>
                        </Form.Select>    
                    </Col>

                    <Col md={{ span: 4, offset: 1 }}>
                        <Form.Group className="mt-5 mb-5">
                            <Form.Label><strong>MINIMA Potenza Laser: {minLaserPowerProduction} W</strong></Form.Label> {/* Display the current value */}
                            <Form.Range
                                min={0} // Set the minimum value
                                max={15000} // Set the maximum value
                                step={1000} // Set the step value
                                value={minLaserPowerProduction}
                                onChange={(e) => setMinLaserPowerProduction(e.target.value)}
                            />
                        </Form.Group>

                        
                        <Form.Group className="mt-5 mb-5">
                            <Form.Label><strong>MASSIMA Potenza Laser: {maxLaserPowerProduction} W</strong></Form.Label> {/* Display the current value */}
                            <Form.Range
                                min={0} // Set the minimum value
                                max={15000} // Set the maximum value
                                step={1000} // Set the step value
                                value={maxLaserPowerProduction}
                                onChange={(e) => setMaxLaserPowerProduction(e.target.value)}
                            />
                        </Form.Group>
                    </Col>


                </Row>

            </Form>

            < ProductionDateRangeSelector />



        </div>
    );
}

export default ProductionForm;
import React, { useEffect, useState } from "react";
import { Row, Col, Spinner } from "react-bootstrap";

import { useGlobalState } from '../../components/globalStateProvider.js';
import { machineSignalSensorsAPI } from '../../api/api_definitions.js';
import SignalSensorsTable from './SignalSensorsTable.js'


const SignalSensorsInfo = () => {

    const [loading, setLoading]             = useState(true);
    const [startAnalysis, setStartAnalysis] = useState(false);

    const {
        machineFamilySensors,
        infoSensors, setInfoSensors,
        tableSensors, setTableSensors
    } = useGlobalState();
  
    // API GATEWAY RESPONSE MANAGEMENT: Return table/info of sensors threshold according to the machine family selected
    useEffect(() => {
      setStartAnalysis(true)
      setLoading(true)
      let isMounted = true;
      machineSignalSensorsAPI(machineFamilySensors)
        .then((dataAPI) => {
          if (isMounted) {
            console.log("Data machineSignalSensorsAPI", dataAPI)
            setLoading(false)
            setInfoSensors(dataAPI)
            if (dataAPI !== null) {
                setTableSensors(JSON.parse(dataAPI.DF))
            }
          }
        }
       )
      .catch((error) => {
        console.log(error)
        setStartAnalysis(false)
        setLoading(true)
      });
      return () => {
        isMounted = false;
      };
    }, [machineFamilySensors, setInfoSensors, setTableSensors])


    // Return loading layout
    if (startAnalysis & loading) {
      return (
        <Row className="d-flex flex-column align-items-center justify-content-center text-center">
          <p className="mt-4">Caricamento dati...</p>
          <Spinner animation="border" role="status"></Spinner>
       </Row>
      )
    }

    // Return layout
    if (infoSensors) {
      const lenDF        = Object.values(Object.values(tableSensors)[0]).length;
      const currentPosts = Object.values(tableSensors).map(i => Object.values(i).slice(0, lenDF));
    
      return (
        <>
          { tableSensors && (
            <Row className="mt-4 mb-4">
              <SignalSensorsTable columns={Object.keys(tableSensors)} posts={currentPosts} />
            </Row>
          )}          
        </>
      )
    }
}



export default SignalSensorsInfo;
import React, { useState } from "react";
import { Row, Button, Modal, Spinner } from "react-bootstrap";

import { useGlobalState } from '../../components/globalStateProvider';
import { machineAnalysisPlotErrorDriverAPI } from '../../api/api_definitions.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';


const ButtonStartAnalysis = () => {
    
    const { startDate, endDate, machineFamily, snMachine, customerName, plotData, plotDataInfo, setPlotData, setPlotDataInfo } = useGlobalState();

    // Modal window management
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [loading, setLoading] = useState(false)

    // Disable/enable button conditions
    const disableButton = startDate === null || endDate === null || snMachine === "na" || snMachine === "";

    const addItemPlot = () => {
        // Manage time range in the correct format
        let year = startDate.getFullYear();
        let month = String(startDate.getMonth() + 1).padStart(2, '0'); // Adding 1 to month because it's zero-based
        let day = String(startDate.getDate()).padStart(2, '0');
        let startDateApi = `${year}-${month}-${day}`;

        year = endDate.getFullYear();
        month = String(endDate.getMonth() + 1).padStart(2, '0'); // Adding 1 to month because it's zero-based
        day = String(endDate.getDate()).padStart(2, '0');
        let endDateApi = `${year}-${month}-${day}`;

        // API GATEWAY RESPONSE MANAGEMENT --> Return error driver plot
        machineAnalysisPlotErrorDriverAPI(machineFamily, customerName, snMachine, startDateApi, endDateApi)
        .then(setLoading(true))
        .then((dataAPI) => {
            if (dataAPI !== null) {
                const parsedData = JSON.parse(dataAPI.DF); 
                const categories = Object.keys(parsedData);
                const values = Object.values(parsedData).map(item => Object.values(item)[0]);

                const color_list = ["#00FFFF", "#7393B3", "#0000FF", "#FFBF00", "#080808", "#F2D2BD"]

                let index_color = 0
                if (plotData.length <= 8) {
                    index_color = plotData.length - 2
                }
                if (plotData.length > 8) {
                    index_color = plotData.length - 8
                }

                const newData = {
                id: dataAPI.id,
                x: categories,
                y: values,
                type: 'bar',
                marker: {"color": color_list[index_color]}, 
                name: `${dataAPI.predictionModel} - ${dataAPI.customerName} - ${dataAPI.snMachine} - ${dataAPI.startDate}/${dataAPI.endDate} - RT: ${dataAPI.runningTime} min` 
                };

                setPlotData([...plotData, newData]);
                setPlotDataInfo([...plotDataInfo, dataAPI]);
                setLoading(false)
            }      
            else {
                setShow(true)
                setLoading(false)
            }
        })
        .catch((error) => {
            console.log(error);
            setShow(true)
            setLoading(false)
        });
    };

    // Remove machine from the analysis
    const removeItem = (idToRemove) => {
        const newData = plotData.filter(item => item.id !== idToRemove);
        const newDataInfo = plotDataInfo.filter(item => item.id !== idToRemove);
        setPlotData(newData);
        setPlotDataInfo(newDataInfo);
    };

    // Return layout
    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>AVVISO</Modal.Title>
                </Modal.Header>
                <Modal.Body> Nessun dato disponibile </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Chiudi</Button>
                </Modal.Footer>
            </Modal>

            { !disableButton && 
                <Row className="text-center mt-4 mb-4">
                    <Button onClick={addItemPlot}> Aggiungi </Button>
                </Row>
            }


            { loading ? 
                <>
                    <p>Caricamento nuovo cliente...</p>
                    <Spinner animation="border" role="status"></Spinner>;
                </> 
                :
                <Row className="text-center mt-4 mb-4">
                    {plotDataInfo.length === 0 ? null : (
                        plotDataInfo.map(item => (
                            <Button size="sm" style={{ whiteSpace: 'pre-wrap' }} className="text-center mt-1 mb-1" variant="info" key={item.id} onClick={() => removeItem(item.id)}>
                            <FontAwesomeIcon icon={faTrash} /> {item.customerName} - {item.snMachine} {'\n'} {item.startDate} / {item.endDate}
                            </Button>
                        ))
                    )}
                </Row>
            }
        </>
    );
};

export default ButtonStartAnalysis;
import React, {useState, useEffect} from 'react'
import { Row, Col, Form, Button, Alert, Spinner } from "react-bootstrap";
import Plot from 'react-plotly.js';

import { useGlobalState } from '../../components/globalStateProvider.js';
import { machineSignalSensorsAnalysisAPI } from '../../api/api_definitions.js'; 

const SignalSensorsPlot = ({ data, sensor_type }) => {

    const { machineFamilySensors } = useGlobalState();

    const [loading, setLoading]                       = useState(true);
    const [startAnalysis, setStartAnalysis]           = useState(false);
    const [infoSpecificSensor, setInfoSpecificSensor] = useState(null);
    const [threshold, setThreshold]                   = useState('');
    const [count, setCount]                           = useState(null);

    
    useEffect(() => {
      setStartAnalysis(true)
      setLoading(true);
      let isMounted = true;

      // API GATEWAY RESPONSE MANAGEMENT: Return info about selected sensor thresholds
      const fetchSensorData = async () => {
        try {
          const ids = data.map(item => item.ID);
          const dataAPI = await machineSignalSensorsAnalysisAPI(machineFamilySensors, ids, sensor_type);
          if (isMounted) {
            console.log('Data', dataAPI);
            setLoading(false);
            setInfoSpecificSensor(dataAPI);
          }
        } 
        catch (error) {
          console.log(error);
          setLoading(true);
          setStartAnalysis(false)
        }
      };

      // If no sensor is selected show no info
      if (sensor_type !== "") {
        fetchSensorData();
      }
      else {
        setLoading(true);
        setStartAnalysis(false)
      }
  
      return () => {
        isMounted = false;
      };
    }, [machineFamilySensors, data, sensor_type])

      // Reset info
      useEffect(() => {
        setThreshold('');
        setCount(null);
      }, [infoSpecificSensor]);
  
      
      // Return loading layout
      if (startAnalysis & loading) {
        return (
          <Row className="d-flex flex-column align-items-center justify-content-center text-center">
            <p className="mt-4">Caricamento dati...</p>
            <Spinner animation="border" role="status"></Spinner>
         </Row>
        )
      }

      // Return not available data layout
      if (!startAnalysis & loading) {
        return (
          <Row className="d-flex flex-column align-items-center justify-content-center text-center">
            <p className="mt-4">Nessuna informazione a disposizione</p>
          </Row>
        )
      }
  

      // Return layout
      if (infoSpecificSensor) {

        // Prepare plot data and layout
        const dataPlot = [
          {
            x: infoSpecificSensor.VALUES,
            type: 'histogram',
            nbinsx: 50
          },
        ]
        const layoutPlot = {
          xaxis: { title: 'Valore soglia' },
          yaxis: { title: 'N. macchine' },
        }

        // Count elements under the threshold 
        const handleSubmit = (event) => {
          event.preventDefault();
          const numericThreshold = parseFloat(threshold);
          if (!isNaN(numericThreshold)) {
            const countUnderThreshold = infoSpecificSensor.VALUES.filter(item => item < numericThreshold).length;
            setCount(countUnderThreshold);
          } else {
            setCount(null);
          }
        };

        // Return layout
        return (
          <>
            <Row className="mt-4 mb-2">
                Valore MINIMO = {infoSpecificSensor.MIN} {sensor_type==='Pressure_threshold' ? 'mBar' : 'u'}
            </Row>
            <Row className="mt-2 mb-2">
              Valore MASSIMO = {infoSpecificSensor.MAX} {sensor_type==='Pressure_threshold' ? 'mBar' : 'u'}
            </Row>
            <Row className="mt-2 mb-2">
              Valore MEDIO = {infoSpecificSensor.AVG} {sensor_type==='Pressure_threshold' ? 'mBar' : 'u'}
            </Row>
            <Row className="mt-2 mb-4">
              DEVIAZIONE STANDARD = {infoSpecificSensor.STD} {sensor_type==='Pressure_threshold' ? 'mBar' : 'u'}
            </Row>

            < Plot data={dataPlot} layout={layoutPlot} />

            <Row className="mb-5">
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3 mt-5" controlId="formThreshold">
                  <Form.Label>Inserisci un valore di soglia</Form.Label>
                  <Form.Control
                    type="number"
                    value={threshold}
                    onChange={(e) => setThreshold(e.target.value)}
                    placeholder="Inserisci numero"
                  />
                </Form.Group>
                <Button variant="primary" type="submit"> Calcola numero elementi sotto la soglia </Button>
                {count !== null && (
                  <Alert variant="info" className="mt-3">
                    {count} {count === 1 ? 'elementi è' : 'elementi sono'} sono sotto la soglia su {infoSpecificSensor.VALUES.length}, corrispondente al {Math.round(100 * count / infoSpecificSensor.VALUES.length)}%.
                  </Alert>
                )}
              </Form>
            </Row>
          </>

        )    
      } 
            
}

export default SignalSensorsPlot
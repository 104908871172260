import React, { useEffect, useState } from "react";
import { Row, Col, Spinner, Modal, Button } from "react-bootstrap";

import { useGlobalState } from '../../components/globalStateProvider.js';
import { machineProductionMachinesAPI } from '../../api/api_definitions.js';

import ProductionPlot from "./PlotComponents/ProductionPlot.js";
import ProductionDetails from "./PlotComponents/ProductionDetails.js"
import ProductionError from "./PlotComponents/ProductionError.js"
import ProductionErrorProd from "./PlotComponents/ProductionErrorProd.js"


const ProductionStartAnalysis = () => {

    const {
        machineFamilyProduction, headTypeProduction, minLaserPowerProduction, maxLaserPowerProduction,
        startDateProduction, endDateProduction, 
        infoPlotProduction, setInfoPlotProduction
    } = useGlobalState();


    // Modal window management
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [displayDate, setDisplayDate] = useState("")
    const [loading, setLoading] = useState(true)
    const [startAnalysis, setStartAnalysis] = useState(false)

    // Filter variable association
    const machineType = ['---', 'LaserGenius+', 'PlatinoLinear']
    const headType    = ['Tutte', 'FSC3', 'Precitec']

    // Disable/enable button conditions
    const disableButton = machineFamilyProduction === 0 || machineFamilyProduction === null || machineFamilyProduction === "0" || startDateProduction === null
    
    // Reset the plot by disabling start analysis variable
    useEffect(() => {
        setStartAnalysis(false);
    }, [machineFamilyProduction, headTypeProduction, minLaserPowerProduction, maxLaserPowerProduction, startDateProduction]); 



    // Call function to plot data
    const startProductionPlot = () => {
      setStartAnalysis(true)
      setLoading(true)
      // Manage time range in the correct format
      let year = startDateProduction.getFullYear();
      let month = String(startDateProduction.getMonth() + 1).padStart(2, '0'); // Adding 1 to month because it's zero-based
      let day = String(startDateProduction.getDate()).padStart(2, '0');
      let startDateApi = `${year}-${month}-${day}`;

      year = endDateProduction.getFullYear();
      month = String(endDateProduction.getMonth() + 1).padStart(2, '0'); // Adding 1 to month because it's zero-based
      day = String(endDateProduction.getDate()).padStart(2, '0');
      let endDateApi = `${year}-${month}-${day}`;

      setDisplayDate(startDateApi + " / " + endDateApi)

      // API GATEWAY RESPONSE MANAGEMENT --> Return error driver plot
      machineProductionMachinesAPI(machineFamilyProduction, headTypeProduction, minLaserPowerProduction, maxLaserPowerProduction, startDateApi, endDateApi)
        .then((dataAPI) => {
            if (dataAPI !== null) {
                console.log("Data machineProductionMachinesAPI", dataAPI)
                setLoading(false)
                setInfoPlotProduction(dataAPI)
            }      
            else {
                setShow(true)
            }
        })
        .catch((error) => {
            setStartAnalysis(false);
            setShow(true)
            console.log(error)
        });
    };


    // Return loading layout
    if (startAnalysis & loading) {
      return (
          <>
              <Row className="text-center mt-4 mb-4">
                  <Col md={{ span: 4, offset: 4 }}>
                      <p>Caricamento info produzione...</p>
                      <Spinner animation="border" role="status"></Spinner>
                  </Col>
              </Row>
          </>
      )
  }

    // Return layout
    return (
      <>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>AVVISO</Modal.Title>
                </Modal.Header>
                <Modal.Body> Nessun dato disponibile </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Chiudi</Button>
                </Modal.Footer>
            </Modal>

            { !disableButton && 
                <Row className="text-center mt-5 mb-4">
                    <Col md={{ span: 4, offset: 4 }}>
                        <Button disabled={disableButton} size='lg' onClick={startProductionPlot}> Inizia Analisi </Button>
                    </Col>
                </Row>
            }


            { startAnalysis && infoPlotProduction !== null && 

              <div >
                <p className="text-center mt-5"><strong>Macchina:</strong> {machineType[machineFamilyProduction]} - <strong>Testa:</strong> {headType[headTypeProduction]}   -   <strong>Range Potenza Laser:</strong> {minLaserPowerProduction}/{maxLaserPowerProduction} W</p>
                <p className="text-center mt-1"><strong>Periodo:</strong> {displayDate}</p>
                <h4 className="text-center mb-5"> Tempo totale di esecuzione programmi: {infoPlotProduction.total_time} h</h4>

                <Row className="mt-5 mb-5">
                    <ProductionPlot values={infoPlotProduction.nozzle_time} labels={infoPlotProduction.nozzle_type} title='Nozzle'  />
                    <ProductionDetails results={infoPlotProduction.nozzle_customers_analysis} />
                    <ProductionError results={infoPlotProduction.error_dict_nozzle} />
                    <ProductionErrorProd results={infoPlotProduction.nozzle_dict_error} />
                </Row>

                <Row className="mb-5">
                    <ProductionPlot values={infoPlotProduction.gas_time} labels={infoPlotProduction.gas_type} title='Gas'  />
                    <ProductionDetails results={infoPlotProduction.gas_customers_analysis} />
                    <ProductionError results={infoPlotProduction.error_dict_gas} />
                    <ProductionErrorProd results={infoPlotProduction.gas_dict_error} />
                </Row>

                <Row className="mb-5">
                    <ProductionPlot values={infoPlotProduction.tool_time} labels={infoPlotProduction.tool_type} title='Nozzle + Gas'  />
                    <ProductionDetails results={infoPlotProduction.tool_customers_analysis} />
                    <ProductionError results={infoPlotProduction.error_dict_tool} />
                    <ProductionErrorProd results={infoPlotProduction.tool_dict_error} />
                </Row>

                <Row className="mb-5"> 
                    <ProductionPlot values={infoPlotProduction.thickness_time} labels={infoPlotProduction.thickness_type} title='Thickness' />
                    <ProductionDetails results={infoPlotProduction.thickness_customers_analysis} />
                    <ProductionError results={infoPlotProduction.error_dict_thickness} />
                    <ProductionErrorProd results={infoPlotProduction.thickness_dict_error} />
                </Row>

              </div>

            } 
      </>
    )
  }




export default ProductionStartAnalysis;
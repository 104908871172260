import React, { useState, useEffect } from "react";
import { Badge } from "react-bootstrap";
import { getCurrentUser } from 'aws-amplify/auth';

import './css/HomePage.css'

// MAIN PAGE
const HomePage = () => {

  const [user, setUser] = useState("")
  
  // Fetch user groups on component mount
  const checkUser = async () => {
    try {
      const user = await getCurrentUser();
      setUser(user.username)
    } catch (error) {
      console.error('Error fetching user groups:', error);
    }
  };

  // Each time the page/component is loaded it retrieves general information and reads the user's access level
  useEffect(() => {
    checkUser();
  }, []);

  return (
    <div className='my-home-page'>
        <Badge bg="warning" text="dark" className="custom-badge">Benvenuto <br/> <br/> {user.toUpperCase()}</Badge>
    </div>
  )
}

export default HomePage;
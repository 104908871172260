import React, { useEffect} from "react";
import { Row, Form } from "react-bootstrap";

import { useGlobalState } from '../../components/globalStateProvider';
import { machineSNListSelectedProductionCustomerAPI } from '../../api/api_definitions.js';


const ProductionCustomerListCustomer = () => {
  const { machineFamilyProductionCustomer, customerNameProductionCustomer, 
          setCustomerNameProductionCustomer, listCustomerProductionCustomer, 
          setListSNMachinesProductionCustomer, setSnMachineProductionCustomer 
        } = useGlobalState();

    // Reset the info
    useEffect(() => {
      setSnMachineProductionCustomer("");
    }, [machineFamilyProductionCustomer, customerNameProductionCustomer]); 


   // API GATEWAY RESPONSE MANAGEMENT WITH MEMO (AVOID DOUBLE CALL) --> RETURN LIST OF CUSTOMERS
   const memoizedMachineSNListSelectedCustomerProductionAPI = (() => {
    let cachedData = {}; // Cache for storing results
    return async (machineFamilyProductionCustomer, customerNameProductionCustomer) => {
      if (!cachedData[machineFamilyProductionCustomer]) {
        cachedData[machineFamilyProductionCustomer] = await machineSNListSelectedProductionCustomerAPI(machineFamilyProductionCustomer, customerNameProductionCustomer);
      }
      return cachedData[machineFamilyProductionCustomer];
    };
  })();

  useEffect(() => {
    let isMounted = true;
    memoizedMachineSNListSelectedCustomerProductionAPI(machineFamilyProductionCustomer, customerNameProductionCustomer)
      .then((dataAPI) => {
        if (isMounted) {
          console.log("Data List SN", dataAPI);
          if (dataAPI !== null) {
            setListSNMachinesProductionCustomer(dataAPI.ListSNMachines);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return () => {
      isMounted = false;
    };
  }, [customerNameProductionCustomer, setListSNMachinesProductionCustomer]);

  // Return layout
  return (
    <Row className="text-center mt-4 mb-4">
      <Form.Select
        aria-label="Default select example"
        as="select"
        value={customerNameProductionCustomer}
        onChange={e => {
          if (e.target.value !== "") {
            setCustomerNameProductionCustomer(e.target.value);
          }
        }}>
        <option value="" disabled>Seleziona un cliente</option>
        {listCustomerProductionCustomer.map((item, index) => (
          <option value={item} key={index}>{item}</option>
        ))}
      </Form.Select>
    </Row>
  );
};

export default ProductionCustomerListCustomer;
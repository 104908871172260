import React, { useEffect} from "react";
import { Row, Form } from "react-bootstrap";

import { useGlobalState } from '../../components/globalStateProvider.js';
import { machineListSelectedProductionCustomerAPI } from '../../api/api_definitions.js';


const ProductionMachineFamilyListCustomer = () => {

    const {machineFamilyProductionCustomer, setMachineFamilyProductionCustomer, 
           setListCustomerProductionCustomer, setCustomerNameProductionCustomer 
      } = useGlobalState();

    // Reset the info
    useEffect(() => {

      setCustomerNameProductionCustomer("");
    }, [machineFamilyProductionCustomer]);
 
    // API GATEWAY RESPONSE MANAGEMENT --> Return list of machines according to their type
    useEffect(() => {
      let isMounted = true;
      machineListSelectedProductionCustomerAPI(machineFamilyProductionCustomer)
      .then((dataAPI) => {
          if (isMounted) {
          if (dataAPI !== null) {
            setListCustomerProductionCustomer(dataAPI.ListMachines)        
            }
          }
      })
      .catch((error) => {
        console.log(error);
      });
      return () => {
        isMounted = false;
      };
    }, [machineFamilyProductionCustomer, setListCustomerProductionCustomer])

    // Return layout
    return (
      <>
        <Row>
          <Form.Select
            aria-label="Default select example"
            as="select"
            value={machineFamilyProductionCustomer}
            onChange={e => {
              if (e.target.value !== '0') {
                setMachineFamilyProductionCustomer(e.target.value);
              }
            }}>
            <option value="0" disabled>Seleziona la famiglia macchina</option>
            <option value="1">Laser Genius +</option>
            <option value="4">Platino Linear</option>

          </Form.Select>
        </Row>
      </>  
    );
};

export default ProductionMachineFamilyListCustomer;
import React, {useState} from 'react'
import DataTable from 'react-data-table-component';

import ColumnVisibilityManager from './DataTableComponents/ColumnVisibilityManager'
import FilterComponent from './DataTableComponents/FilterComponent';
import ExportComponent, { downloadCSV } from './DataTableComponents/ExportComponent';
import { prepareData } from './DataTableComponents/DataUtils';


const MachineListInfoML = ({ columns, posts }) => {

    // List of columns to hide
    const startColumnsHidden = ["customerID",
                                "1335-Tip Touch Hardware",
                                "1337-Tip Touch Software",
                                "1374-Check Optics Failed Lisa",
                                "27-LPM Plasma Detect Lisa",
                                "310-Head collision protection tripped",
                                "7502-Lens protection glass missing",
                                "7503-Head Lens is not present",
                                "7510-Anomaly increase in sensor nozzle temperature (variation)",
                                "7511-Anomaly increase in Sensor nozzle temperature (threshold)",
                                "790-Lanny Gas Input pressure Too LOW",
                                "9047-Scattering Over Limit",
                                "9053-Anomalous increase in pressure inside the collimator",
                                "3000-Laser generic error",
                                "Probability_1",
                                ]

    const [hiddenColumns, setHiddenColumns] = useState(startColumnsHidden);

    const [filterCustomerText, setFilterCustomerText]   = useState('');
    const [filterSnMachineText, setFilterSnMachineText] = useState('');
    const [filterLaserText, setFilterLaserText]         = useState('');
    const [filterHeadText, setFilterHeadText]           = useState('');

    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    // Prepare data
    const { columnManipulation, rawManipulation } = React.useMemo(
      () => prepareData(posts, columns, hiddenColumns),
      [posts, columns, hiddenColumns]
    );

    const toggleColumnVisibility = columnName => {
      setHiddenColumns(prev => 
        prev.includes(columnName) ? prev.filter(col => col !== columnName) : [...prev, columnName]
      );
    };

    // Filter data
    const filteredItems = rawManipulation.filter(item => {
        return (
          item.Customer && item.Customer.toLowerCase().includes(filterCustomerText.toLowerCase()) &&
          item.snMachine && item.snMachine.toLowerCase().includes(filterSnMachineText.toLowerCase()) &&
          item.Laser && item.Laser.toLowerCase().includes(filterLaserText.toLowerCase()) &&
          item.Head && item.Head.toLowerCase().includes(filterHeadText.toLowerCase()) 
        );
    });
  
    const subHeaderComponentMemo = React.useMemo(() => {
      const snMachineOptions = [...new Set(rawManipulation.map(item => item.snMachine).filter(option => option !== null))];
      const laserTypeOptions = [...new Set(rawManipulation.map(item => item.Laser).filter(option => option !== null))];
      const headTypeOptions = [...new Set(rawManipulation.map(item => item.Head).filter(option => option !== null))];

      const handleClearCustomer = () => {
        if (filterCustomerText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterCustomerText('');
        }
      };
      const handleClearSnMachine = () => {
        if (filterSnMachineText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterSnMachineText('');
        }
      };
      const handleClearLaser = () => {
        if (filterLaserText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterLaserText('');
        }
      };
      const handleClearHead = () => {
        if (filterHeadText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterHeadText('')
        }
      };
  
      return (
        <FilterComponent onFilterCustomer={e => setFilterCustomerText(e.target.value)} 
                         filterCustomer={filterCustomerText} 
                         onFilterSnMachine={e => setFilterSnMachineText(e.target.value)} 
                         filterSnMachine={filterSnMachineText}
                         onFilterLaser={e => setFilterLaserText(e.target.value)} 
                         filterLaser={filterLaserText}
                         onFilterHead={e => setFilterHeadText(e.target.value)} 
                         filterHead={filterHeadText}
                         snMachineOptions={snMachineOptions}
                         laserTypeOptions={laserTypeOptions}
                         headTypeOptions={headTypeOptions}
                         onClearCustomer={handleClearCustomer} 
                         onClearSnMachine={handleClearSnMachine}
                         onClearLaser={handleClearLaser}
                         onClearHead={handleClearHead}
        />
      );
    }, [filterCustomerText, filterSnMachineText, filterLaserText, filterHeadText, rawManipulation, resetPaginationToggle]);
    

    // Conditional color logic
    const conditionalRowStyles = [
      {
        when: row => row.Probability_0 >= 0.25,
        style: {
            backgroundColor: 'rgba(250, 218, 94, 0.9)',
            color: 'black',
        },
      },
      {
        when: row => row.Probability_0 >= 0.5,
        style: {
            backgroundColor: 'rgba(255, 165, 0, 0.9)',
            color: 'black',
        },
      },
      {
        when: row => row.Probability_0 >= 0.75,
        style: {
            backgroundColor: 'rgba(150, 40, 27, 0.9)',
            color: 'white',
        },
      },
    ];

    // Custom styles for the header
    const customStyles = {
      headCells: {
        style: {
          fontWeight: 'bold',
        },
      }   
    };

    // Return layout
    return (
      <>
        <ColumnVisibilityManager
          columns={columnManipulation}
          hiddenColumns={hiddenColumns}
          toggleColumnVisibility={toggleColumnVisibility}
        />

        <DataTable
          columns = {columnManipulation.filter((col) => !col.omit)}
          data = {filteredItems}
          pagination  
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          actions={<ExportComponent onExport={() => downloadCSV(rawManipulation)} />}
          persistTableHead
          conditionalRowStyles={conditionalRowStyles}
          customStyles={customStyles}
          dense
          className='enable-sticky'
        />
    </>
  )
}

export default MachineListInfoML
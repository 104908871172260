import React from "react";
import { Row, Col, Form, Container } from "react-bootstrap";

import { useGlobalState } from '../../components/globalStateProvider.js';
import MachineStatusInfoML from "./MachineStatusInfoML.js";


// MAIN PAGE
function AnalisiOverviewMLPage() {

    const {
        machineFamilyML, setMachineFamilyML
      } = useGlobalState();

    return (
        <>
            <Container >
            <Row className="text-center mt-4 mb-4">
                <h2>Stato macchine ultimi 30 giorni</h2>
                <p className='mt-1'>Questa sezione offre indicazioni sullo stato delle macchine, in termini di consumo vetrino, negli ultimi 30 giorni utilizzando la tecnologia del Machine Learning e un algoritmo basato su soglie</p>
            </Row>

            <Row className="text-center mt-4 mb-4">
                <Col md={{ span: 6, offset: 3 }}>
                <Form.Select aria-label="Default select example" as="select"
                             value={machineFamilyML}
                             onChange={e => {
                                            if (e.target.value !== "0") {
                                                console.log("e.target.value", e.target.value);
                                                setMachineFamilyML(e.target.value);
                                            }
                             }}>
                    <option value="0" disabled>Seleziona la famiglia macchina</option>
                    <option value="1">Laser Genius +</option>
                </Form.Select>
                </Col>
            </Row>

            < MachineStatusInfoML />

          </Container>
                           
        </>
    )

}

export default AnalisiOverviewMLPage;
import React, {useState} from 'react';
import { Row, Col, InputGroup, Button, Form, Container } from 'react-bootstrap';


const FilterComponent = ({ filterCustomer, 
                           onFilterCustomer, 
                           filterSnMachine, 
                           onFilterSnMachine, 
                           filterLaser, 
                           onFilterLaser,
                           filterHead, 
                           onFilterHead, 
                           snMachineOptions,
                           laserTypeOptions,
                           headTypeOptions, 
                           onClearCustomer,
                           onClearSnMachine,
                           onClearLaser,
                           onClearHead }
                          
                        ) => {

  const [showFilter, setShowFilter] = useState(false);
  
  return (

    <Container>

      <Row className="mt-3 mb-3">
        <Col>
            <Button onClick={() => setShowFilter(!showFilter)}>
              {showFilter ? "Chiudi Gestione Filtro" : "Apri Gestione Filtro"}
            </Button>
        </Col>
      </Row>

      {showFilter && (

        <Row className="text-center mb-5">

          <Col md={4} className="mb-2"> 
            <InputGroup>
              <Form.Control
                id="search"
                type="text"
                placeholder="Filter By Customer Name"
                value={filterCustomer}
                onChange={onFilterCustomer}
              />
              <Button type="button" onClick={onClearCustomer}> X </Button>
            </InputGroup>
          </Col>

          <Col md={4} className="mb-2">
            <InputGroup >
              <Form.Control
                id="search-sn-machine"
                as="select"
                value={filterSnMachine}
                onChange={onFilterSnMachine}
              >
                <option value="">Filter By SN Machine</option>
                {snMachineOptions.sort().map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </Form.Control>
              <Button type="button" onClick={onClearSnMachine}>X</Button>
            </InputGroup>
          </Col>

          <Col md={4} className="mb-2">
            <InputGroup>
              <Form.Control
                id="search-head-type"
                as="select"
                value={filterLaser}
                onChange={onFilterLaser}
              >
                <option value="">Filter By Laser Type</option>
                {laserTypeOptions.sort().map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </Form.Control>
              <Button type="button" onClick={onClearLaser}>X</Button>
            </InputGroup>
          </Col>

          <Col md={4} className="mb-2">
            <InputGroup>
              <Form.Control
                id="search-head-type"
                as="select"
                value={filterHead}
                onChange={onFilterHead}
              >
                <option value="">Filter By Head Type</option>
                {headTypeOptions.sort().map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </Form.Control>
              <Button type="button" onClick={onClearHead}>X</Button>
            </InputGroup>
          </Col>

        </Row>

      )}

    </Container>

  );

}

export default FilterComponent;
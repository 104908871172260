import React, {useState, useEffect} from 'react';
import { Modal, Button, Container, Row, Spinner } from 'react-bootstrap';
import Plot from 'react-plotly.js';

import { machineAnalysisPlotErrorTrendCustomerAPI } from '../../../api/api_definitions.js';
import { description, remedy } from './Descriprtion_Remedy.js';


const ErrorInfo = ({ show, handleClose, content, errorToAnalyze, labelToAnalyze, snMachineSelectedCustomer}) => {

    const [modalData, setModalData] = useState(null);
    const [loading, setLoading] = useState(true)

    // API GATEWAY RESPONSE MANAGEMENT --> Return error driver trend plot (customer POV)
    useEffect(() => {
      let isMounted = true;

      setLoading(true);

      const fetchData = async () => {
        try {
          
          const dataAPI = await machineAnalysisPlotErrorTrendCustomerAPI(errorToAnalyze, labelToAnalyze);
    
          if (isMounted) {
            console.log('machineAnalysisPlotErrorTrendCustomerAPI result', dataAPI);
            
            const DF = JSON.parse(dataAPI.DF);
            const DF2 = JSON.parse(dataAPI.DF2);
    
            const timeDays = Object.values(DF.time_days).map(t => new Date(t));
            const numberOfErrors = Object.values(DF.number_of_error);
            const runningDurationIncremental = Object.values(DF.runningDurationIncremental);
            const glassOpeningTime = Object.values(DF.glassOpeningTime);
    
            const plotData = Object.keys(DF2).map(tools => {
              const timestamps = Object.keys(DF2[tools]);
              const values = Object.values(DF2[tools]);
    
              return {
                x: timestamps.map(ts => new Date(Number(ts))),
                y: values,
                type: 'bar',
                name: tools
              };
            });
    
            const data = [
              {
                x: timeDays,
                y: numberOfErrors,
                type: 'scatter',
                mode: 'lines+markers',
                name: 'Number of Errors',
                yaxis: 'y',
                marker: { color: 'red' },
              },
              {
                x: timeDays,
                y: runningDurationIncremental,
                type: 'scatter',
                mode: 'markers',
                name: 'Running Duration Incremental [hour]',
                yaxis: 'y2',
                marker: { color: 'blue' }
              },
              {
                x: timeDays,
                y: glassOpeningTime,
                type: 'scatter',
                mode: 'lines+markers',
                name: 'Glass Opening Time total [s]',
                yaxis: 'y3',
                marker: { color: 'green' }
              },
              ...plotData
            ];
    
            setModalData(data);
          }
        } catch (error) {
          console.log(error);
        } finally {
          if (isMounted) setLoading(false); // Set loading state to false after the API call completes
        }
      };
    
      fetchData();
    
      return () => {
        isMounted = false;
      };
    }, [errorToAnalyze, labelToAnalyze]);

        

      // Plot layout
    const layout = {
      xaxis: { 
        title: 'Time (days)' 
      },
      yaxis: { 
        title: 'Number of Errors', 
        side: 'left',
        rangemode:'tozero'
      },
      yaxis2: {
        title: 'Running Duration Incremental [hour]',
        overlaying: 'y',
        side: 'right',
        rangemode:'tozero'
      },
      yaxis3: {
        title: 'Glass Opening Time [s]',
        anchor:'free',
        overlaying: 'y',
        side: 'right',
        autoshift: true,
        shift:20,
        rangemode:'tozero'
      },
      width: 1600, 
      height: 800,  
      legend: {
        orientation: 'h',
        x: 0.45,
        y: -0.2,
        xanchor: 'center',
        yanchor: 'top'
      }
    }

    console.log(errorToAnalyze)
    
    // Description & Remedy section
    const descriptionStr = description(errorToAnalyze)
    const remedyStr      = remedy(errorToAnalyze)

    // Return modal layout
    return (
        <Modal show={show} onHide={handleClose} className='custom-modal'>
            <Modal.Header closeButton>
                <Modal.Title>Error Details - {content} - {snMachineSelectedCustomer} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row className='mb-2'>
                  <h4>Description</h4>
              </Row>
              <Row>
                  <p>{descriptionStr}</p>
              </Row>
              <Row className='mt-4 mb-2'>
                  <h4>Remedy</h4>
              </Row>
              <Row>
                  <p>{remedyStr}</p>
              </Row>
            </Modal.Body>

            { loading ? (
              <Container>
                <Row className="d-flex flex-column align-items-center justify-content-center text-center mt-5 mb-5">
                  <h5>Loading the detail plot...</h5>
                  <Spinner animation="border" role="status"></Spinner>
                </Row>
              </Container>

              ) : ( 
                <Modal.Body>
                  <Plot data={modalData} layout={layout} /> 
                </Modal.Body>
              ) }
            
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}> Close </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ErrorInfo;
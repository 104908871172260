function reverseTable(posts) {
    return posts[0].map((_, colIndex) => posts.map(row => row[colIndex]));
}

export function prepareData(posts, columns, hiddenColumns) {

    const reversedTable = reverseTable(posts);

    const columnManipulation = columns.map(col => ({
        name: col,
        selector: row => row[col],
        sortable: true,
        filterable: true,
        wrap: true,
        omit: hiddenColumns.includes(col),
        style: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        headerStyle: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    }));

    const rawManipulation = reversedTable.map(raw => raw.reduce((obj, item, index) => {
        obj[columns[index]] = item;
        return obj;
    }, {}));

    return { columnManipulation, rawManipulation };

}

import React from "react";
import { Row, Col, Container, Form } from "react-bootstrap";

import { useGlobalState } from '../../components/globalStateProvider.js';

import SignalSensorsInfo from './SignalSensorsInfo.js'


// MAIN PAGE
function AnalisiSegnaliSensoriPage() {

    const { machineFamilySensors, setMachineFamilySensors } = useGlobalState();

    return (
        <div>
            <Container>
            <Row className="text-center mt-4 mb-4">
                <h2>Analisi sensoristica</h2>
                <p className="mt-1">Questa sezione fornisce una panoramica delle soglie impostate per i principali sensori presenti sulla macchina, analizzando per ciascuno la distribuzione e i relativi valori statistici</p>
            </Row>

            <Row className="text-center mt-4 mb-4">
                <Col md={{ span: 6, offset: 3 }}>
                    <Form.Select aria-label="Default select example" as="select"
                                 value={machineFamilySensors}
                                 onChange={e => {
                                                if (e.target.value !== "0") {
                                                    console.log("e.target.value", e.target.value);
                                                    setMachineFamilySensors(e.target.value);
                                                }
                                 }}>
                        <option value="0" disabled>Seleziona la famiglia macchina</option>
                        <option value="1">Laser Genius +</option>
                        <option value="2">Platino Linear</option>
                    </Form.Select>
                </Col>
            </Row>

             <SignalSensorsInfo />                  
            
            </Container>       

        </div>
    );
}

export default AnalisiSegnaliSensoriPage;
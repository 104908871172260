import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import { GlobalStateProvider } from './components/globalStateProvider';

import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <GlobalStateProvider>
        <App />
    </GlobalStateProvider>
);

reportWebVitals();

import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import ProductionMachineFamilyListCustomer from './ProductionMachineFamilyListCustomer.js'
import ProductionCustomerListCustomer from './ProductionCustomerListCustomer.js';
import ProductionSnMachineListCustomer from './ProductionSnMachineListCustomer.js';
import ProductionDateRangeSelectorCustomer from './ProductionDateRangeSelectorCustomer.js';
import ProductionStartAnalysisCustomer from './ProductionStartAnalysisCustomer.js';

import { useGlobalState } from '../../components/globalStateProvider.js';


// MAIN PAGE
function AnalisiProduzioneClientePage() {    
    
    const { machineFamilyProductionCustomer, listCustomerProductionCustomer, customerNameProductionCustomer, 
            listSNMachinesProductionCustomer, snMachineProductionCustomer
        } = useGlobalState();

    return (
        <div>
            <Container>
                <Row className="text-center mt-4 mb-4">
                    <h2>Analisi produzione cliente</h2>
                    <p className="mt-1">Questa sezione presenta una panoramica della produzione del cliente selezionato nel periodo considerato 
                        e la sua distribuzione in base a vari parametri, come nozzle, gas e spessore.</p>
                </Row>

                <Row>
                    <Col md={{span:6, offset:3}}>
                        <ProductionMachineFamilyListCustomer/>
                        {machineFamilyProductionCustomer !== 0 && machineFamilyProductionCustomer !== "0" && listCustomerProductionCustomer.length > 0 && <ProductionCustomerListCustomer />}
                        {machineFamilyProductionCustomer !== 0 && machineFamilyProductionCustomer !== "0" && customerNameProductionCustomer !== "" && listSNMachinesProductionCustomer.length > 0 && <ProductionSnMachineListCustomer />}
                    </Col>
                </Row>

                <Row>
                    <Col md={{span:4, offset:4}}>
                        {machineFamilyProductionCustomer !== 0 && customerNameProductionCustomer !== "" && listSNMachinesProductionCustomer.length > 0 && snMachineProductionCustomer !== "" && <ProductionDateRangeSelectorCustomer />}
                    </Col>
                    <ProductionStartAnalysisCustomer />
                </Row>
            </Container>        
        </div>
    );
}

export default AnalisiProduzioneClientePage;
import React from 'react'

import DataTable from 'react-data-table-component';


const TableConnectionCustomerID = ({ columns, posts }) => {

  // List of columns to hide
  const startColumnsHidden = ['ID', 'CADID', 'customerID', 'deviceID', 'deviceName','deviceSize', 'versionNumber','created','Days_passed']

  // Prepare data
  let columnManipulation = columns.map( col => {
    return(
      { 
        name: col, 
        selector: row => row[col],
        sortable: true,
        wrap: true,
        omit: startColumnsHidden.includes(col), 
      }
    );
  });

  let rawManipulation = Object.keys(posts.ID).map(key => {
    const row = {};
    for (const column in posts) {
      row[column] = posts[column][key];
    }
    return row;
  });

  // Conditional color logic
  const conditionalRowStyles = [
    {
      when: row => row.Days_passed > 5,
      style: {
        color: 'rgba(150, 40, 27, 0.9)',
      },
    },
    {
      when: row => row.Days_passed < 0,
      style: {
        color: 'rgba(150, 40, 27, 0.9)',
      },
    },
  ];

  // Custom styles for the header
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: '#ffcc00', 
        color: '#000', 
        fontWeight: 'bold',
        fontSize: '11px',
      },
    },
  };

  // Return table layout
  return (
    <>
      <DataTable
          columns = {columnManipulation.filter((col) => !col.omit)}
          data = {rawManipulation}
          persistTableHead
          conditionalRowStyles={conditionalRowStyles}
          dense
          customStyles={customStyles}
      />
    </>

  )
    
}

export default TableConnectionCustomerID
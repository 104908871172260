import React, {useState, useEffect } from 'react';
import { NavLink, useNavigate } from "react-router-dom"
import { Navbar, Nav, Container, NavDropdown, Image } from "react-bootstrap";
import { signOut, fetchAuthSession } from 'aws-amplify/auth';

import './CustomNavbar.css'
import loadLogo from '../../../static/primapowerlogo.png'


const CustomNavbar = () => {

  const [isAdmin, setIsAdmin]         = useState(false);
  const [isInternal, setIsInternal] = useState(false);
  const [isCustomer, setIsCustomer] = useState(false);
  const [loading, setLoading]         = useState(true); 

  const checkUserIsAdmin = async () => {
    try {
      const session = await fetchAuthSession();
      const accessTokenPayload = session.tokens.idToken.payload['cognito:groups'];
      const groups = accessTokenPayload || [];
      if (groups) {
        console.log("User status", groups)
        if (groups.includes('admins')) {
          setIsAdmin(true);
          setIsInternal(true);
          setIsCustomer(false)
        }
        if (groups.includes('internals')) {
          setIsAdmin(false);
          setIsInternal(true);
          setIsCustomer(false)
        }
        if (groups.includes('customers')) {
          setIsAdmin(false);
          setIsInternal(false);
          setIsCustomer(true)
        }
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching user groups:', error);
    }
  };

  // Check the user status each time the page is refreshed
  useEffect(() => {
    checkUserIsAdmin(); 
  }, []);

  
  // LOGOUT SECTION (AMPLIFY) --> redirect to homepage
  const my_navigate = useNavigate();

  async function handleSignOut() {
    try {
      await signOut();
      my_navigate('/');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  
  return (
    <>
      { loading ? (<></>) :  
        (
          <Navbar collapseOnSelect expand="lg" className="my-navbar" data-bs-theme="dark" >
            <Container>
              <Navbar.Brand as={NavLink} to='/' className="d-flex align-items-center">
                  <Image src={loadLogo} />
                  <h5 className="display-5 ms-3">Monitoring</h5>
              </Navbar.Brand>

              <Navbar.Toggle aria-controls="navbarScroll" />

              <Navbar.Collapse id="navbarScroll">

                <Nav className="justify-content-end flex-grow-1 pe-5 gap-3 size-navlink" >

                { isInternal && 
                  <NavDropdown title="Analisi">
                    <NavDropdown.Item as={NavLink} to='/analisi-connessione-macchine'>Overview connessione macchine</NavDropdown.Item> 
                    <NavDropdown.Divider /> 
                    <NavDropdown.Item as={NavLink} to='/analisi-stato-macchine'>Overview stato macchina</NavDropdown.Item> 
                    <NavDropdown.Item as={NavLink} to='/analisi-errore-driver'>Analisi errori macchina</NavDropdown.Item> 
                    <NavDropdown.Divider />
                    <NavDropdown.Item as={NavLink} to='/analisi-segnali-sensori'>Analisi sensoristica</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item as={NavLink} to='/analisi-produzione'>Analisi produzione</NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} to='/analisi-produzione-cliente'>Analisi produzione cliente</NavDropdown.Item>
                  </NavDropdown>
                }

                { isCustomer && 
                    <NavDropdown title="Info">
                      <Nav.Link as={NavLink} to='/analisi-overview-cliente'>Overview</Nav.Link> 
                    </NavDropdown>
                }

                  <NavDropdown title="MyProfile">
                    <NavDropdown.Item onClick={handleSignOut}>Logout</NavDropdown.Item>

                    { isCustomer ? 
                        <NavDropdown.Item as={NavLink} to='/change-password'>Change Password</NavDropdown.Item> // English version (if customer)
                      :
                        <NavDropdown.Item as={NavLink} to='/change-password'>Cambio Password</NavDropdown.Item> // Italian version (if internal/admin)
                    }

                    { isAdmin && 
                      <>
                        <NavDropdown.Divider />
                        <NavDropdown.Item as={NavLink} to='/create-new-user'>Creazione nuovo utente</NavDropdown.Item> 
                      </>
                    }

                  </NavDropdown>

                </Nav>

              </Navbar.Collapse>

            </Container>
          </Navbar>
        )
      }
    </>
  );
}

export default CustomNavbar;


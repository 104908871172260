import React, { useEffect, useState } from "react";
import { Row, Col, Spinner, ListGroup } from "react-bootstrap";
import Plot from 'react-plotly.js';

import { useGlobalState } from '../../components/globalStateProvider.js';
import { machineStatusMLAPI } from '../../api/api_definitions.js';
import MachineListInfoML from "./MachineListInfoML.js";
import MachineListInfoThreshold from "./MachineListInfoThreshold.js";


const MachineStatusInfoML = () => {

    const [loading, setLoading] = useState(true);
    const [startAnalysis, setStartAnalysis] = useState(false);

    const {
        machineFamilyML,
        infoMachineStatusML, setInfoMachineStatusML,
        tableMachineStatusML, setTableMachineStatusML,
        tableMachineStatusThresholds, setTableMachineStatusThresholds
      } = useGlobalState();
      

    // API GATEWAY RESPONSE MANAGEMENT --> Return list of laser machine status using machine learning model
    useEffect(() => {
        setStartAnalysis(true)
        setLoading(true)
        let isMounted = true;
        machineStatusMLAPI(machineFamilyML)
        .then((dataAPI) => {
            if (isMounted) {
                console.log("Data", dataAPI)               
                if (dataAPI !== null) { 
                    setTableMachineStatusML(JSON.parse(dataAPI.DF_ML))
                    setTableMachineStatusThresholds(JSON.parse(dataAPI.DF_THRESHOLDS))
                  }

                setInfoMachineStatusML(dataAPI)
                setLoading(false)

            }
        })
        .catch((error) => {
            console.log(error);
            setStartAnalysis(false)
            setLoading(true)
        });
        return () => {
            isMounted = false;
        };
    }, [machineFamilyML, setInfoMachineStatusML, setTableMachineStatusML, setTableMachineStatusThresholds])


    // Return loading layout
    if (startAnalysis & loading) {
        return (
        <Row className="d-flex flex-column align-items-center justify-content-center text-center">
            <p className="mt-4">Caricamento dati...</p>
            <Spinner animation="border" role="status"></Spinner>
        </Row>
        )
    }

    // Return layout
    if (infoMachineStatusML!==null) {
        
        // Prepare data for generic plot and tables
        const lenDF        = Object.values(Object.values(tableMachineStatusML)[0]).length;
        const currentPosts = Object.values(tableMachineStatusML).map(i => Object.values(i).slice(0, lenDF));
        const colors       = ["darkgreen", "gold", "darkorange", "red"];
        const labels       = ['Buono', 'Accettabile (moderatamente buono)', 'Avvertimento (moderatamente critico)', 'Critico'];
        const values       = [Number(infoMachineStatusML.CountGood), Number(infoMachineStatusML.CountAcceptable), Number(infoMachineStatusML.CountWarning), Number(infoMachineStatusML.CountBad)];

        const lenDF_thresholds        = Object.values(Object.values(tableMachineStatusThresholds)[0]).length;
        const currentPosts_thresholds = Object.values(tableMachineStatusThresholds).map(i => Object.values(i).slice(0, lenDF_thresholds));
  
        const plotData = [{
          values: values,
          labels: labels,
          type: 'pie',
          marker: { colors: colors },
          sort: false 
        }];
  
        const plotLayout = {
          width: 550,
          height: 550,
          legend: { 
            itemsizing: 'constant',
            orientation: 'h',
            x: 0.5,
            y: 1.4,
            xanchor: 'center',
            yanchor: 'top'
           }, 
          categoryorder: 'array', 
          categoryarray: labels 
        };

        // Return layout
        return (

            <>
                { tableMachineStatusML && (
                    <>
                        <div className="my-spacer"></div>

                        <Row className="text-center"> 
                            <h3>Analisi modello Machine Learning</h3>
                        </Row>

                        <Row className="text-center">
                            <Col md={{ span: 6, offset: 3 }}>
                            <Plot data={plotData} layout={plotLayout}/>
                            </Col>
                        </Row>
                                
                        <Row className="mb-4">
                            <MachineListInfoML columns={Object.keys(tableMachineStatusML)} posts={currentPosts} />
                        </Row>

                        <Row>
                            <h5>Legenda:</h5>
                            <ListGroup>
                                <ListGroup.Item style={{ backgroundColor:'rgba(150, 40, 27, 0.9)', color:'white', width:'40%', }}>Confidenza "CRITICO" oltre il 75%: CRITICO</ListGroup.Item>
                                <ListGroup.Item style={{ backgroundColor:'rgba(255, 165, 0, 0.9)',width:'40%', }}>Confidenza "CRITICO" tra il 50% il 75%: AVVERTIMENTO</ListGroup.Item>
                                <ListGroup.Item style={{ backgroundColor:'rgba(250, 218, 94, 0.9)',width:'40%', }}>Confidenza "CRITICO" tra il 25% e il 50%: ACCETTABILE</ListGroup.Item>
                                <ListGroup.Item style={{ width:'40%', }}>Confidenza "CRITICO" sotto il 25%: BUONO</ListGroup.Item>
                            </ListGroup>
                        </Row>

                        <div className="my-spacer"></div>

                        <h3 className="text-center">Analisi algoritmo Soglie</h3>

                        <Row className="mt-4 mb-4">
                            <MachineListInfoThreshold columns={Object.keys(tableMachineStatusThresholds)} posts={currentPosts_thresholds} />
                        </Row>

                        <div className="my-spacer"></div>      
                    </>
                )}
            </>
        )    
    } 

}

export default MachineStatusInfoML;
/**
 * The function `CreateNewUser` is a React component that allows users to create a new user by
 * providing a username and selecting a group, displaying success or error messages accordingly.
 * @returns The `CreateNewUser` component is being returned. It is a functional component in React that
 * contains a form for creating a new Cognito user. The form includes input fields for username and group
 * selection, along with a button to submit the form. Depending on the outcome of the form submission,
 * either a success message or an error message is displayed below the form.
 */
import React, { useState } from 'react';
import { Col, Form, Button, Container } from "react-bootstrap";

import { createNewUserAPI } from "../../../api/api_definitions"

import './CreateNewUser.css'

const CreateNewUser = () => {

    const [username, setUsername] = useState('')
    const [group, setGroup]       = useState('');

    const [error, setError]     = useState('');
    const [success, setSuccess] = useState('');
  
  
    const manageNewUser = async () => {
      try {
          await createNewUserAPI({ username, group });
          console.log('User created correctly');
          setSuccess('User created correctly');
          setError('')
      } 
      catch (error) {
        console.log('Error creating user:', error);
        setError(error.message);
        setSuccess('')
      }
    };

    return (
      <div className='my-form-create-new-user'>
        <Container>
            <Form>
                <Form.Group className="mb-5">
                    <Form.Label className="text-center" style={{width: "100%"}}><h5>Inserisci nome utente</h5></Form.Label>
                    <Form.Control size="lg" placeholder="Nome utente" value={username}  onChange={(e) => setUsername(e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-5 mt-5">
                    <Form.Label className="text-center" style={{width: "100%"}}><h5>Inserisci gruppo</h5></Form.Label>
                    <Form.Select size="lg" aria-label="Default select example" as="select"
                                value={group}
                                onChange={e => {
                                                if (e.target.value !== 0) {
                                                    setGroup(e.target.value);
                                                }
                                }}>
                        <option value="0">Seleziona gruppo</option>
                        <option value="internals">Internals</option>
                        <option value="customers">Customers</option>
                    </Form.Select>
                </Form.Group>

                <Col className="text-center">
                    <Button className="mt-5" style={{width: "40%"}} size="lg" variant="dark" onClick={manageNewUser}> CREA NUOVO UTENTE </Button>
                </Col>

                {error && <h5 className="text-danger mt-5 text-center">{error}</h5>}
                {success && <h5 className="text-success mt-5 text-center">{success}</h5>}
            </Form>
        </Container>
    </div>
    );
  };
  
  export default CreateNewUser;
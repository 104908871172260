import React from "react";
import Plot from 'react-plotly.js';
import { Modal, Button, Spinner, Container } from 'react-bootstrap';


const PlotComponentErrorTrend = ({ show, loadingPlot, handleClose, data, error_string, customer_string }) => {

  const layout = {
    title: customer_string,
    xaxis: { 
      title: 'Time (days)' 
    },
    yaxis: { 
      title: 'Number of Errors', 
      side: 'left',
      rangemode:'tozero'
    },
    yaxis2: {
      title: 'Running Duration Incremental [hour]',
      overlaying: 'y',
      side: 'right',
      rangemode:'tozero'
      },
    yaxis3: {
      title: 'Glass Opening Time [s]',
      anchor:'free',
      overlaying: 'y',
      side: 'right',
      autoshift: true,
      shift:20,
      rangemode:'tozero'
    },
    width: 1600, 
    height: 800,  
    legend: {
      orientation: 'h',
      x: 0.45,
      y: -0.2,
      xanchor: 'center',
      yanchor: 'top'
    }
  }
 
  return (
    <Modal show={show} onHide={handleClose} className='custom-modal'>

    { loadingPlot? 
      <>   
        <Modal.Header closeButton>
          <Modal.Title>Trend Errore</Modal.Title>
        </Modal.Header>
        <Container>
          <p className="mt-4">Caricamento dati...</p>
          <Spinner className="mb-4" animation="border" role="status"></Spinner>;
        </Container>
      </> 
      :
      <>
        <Modal.Header closeButton>
          <Modal.Title>Trend Error - {error_string}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Plot data={data} layout={layout} />
        </Modal.Body>
      </>
      }

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
  
};

export default PlotComponentErrorTrend;
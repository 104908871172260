export const description = (error) => {

    const list_description = {
        "27": "The LPM sensor detected reflections while cutting",
        "310": "The head collision protection tripped due to a collision",
        "790": "The Lanny Gas Input pressure too low",
        "1335": "Sensor protections detected a collision, an immediate stop was forced to protect the machine",
        "1337": "Sensor protections detected a contact with the sheet metal, an immediate stop was forced to protect the machine",
        "1374": "Check Optics failed: lens protective glass may be seriously damaged. Check it and/or replace it",
        "3000": "High voltage not present, the laser was commanded before it was ready, wait for the ignition phase to be completed and start the program again",
        "7502": "No protection glass detected",
        "7503": "No protection glass/lens detected",
        "7510": "The temperature of the head rose too quickly",
        "7511": "The predicted temperature of the head a reached critical value",
        "9041": "The temperature of the collimator lens rose to critical temperature, the machine stopped",
        "9043": "The temperaure of the collimator housing temperature rose to critical temperature, the machine stopped",
        "9045": "The humidity of the collimator reached a critical value, machine stopped",
        "9047": "Excessive scattering due to contamination detected inside the collimator, machine stopped",
        "9052": "Excessive pressure inside the collimator, machine stopped",
        "9053": "Excessive pressure inside the collimator, machine stopped"
    }
    // Loop through the keys in the list_remedy object to find if any key is included in the error string
    for (const key in list_description) {
        if (error.includes(key)) {
            return list_description[key]; // Return the remedy corresponding to the key found in the error string
        }
    }

    // Return a default message if no key matches
    return "No description found for the specified error code.";

}




export const remedy = (error) => {

    const list_remedy = {
        "27": "Check Lens protection Glass and control Cutting Process Parameters",
        "310": "Move to manual mode and use the axis overtravel bypass key and move it upwards",
        "790": "Check input Process Gas Pressure",
        "1335": "Check the state and correct positioning of the sheet; check for any deposit of material on the sheet and near the piercing point; check the wear and condition of the nozzle, clean the nozzle if necessary, then re-calibrate the sensor.",
        "1337": "Check the state and correct positioning of the sheet; check for any deposit of material on the sheet and near the piercing point; check the wear and condition of the nozzle, clean the nozzle if necessary, then re-calibrate the sensor.",
        "1374": "Check Lens protection Glass. If it has impurities, clean or replace it",
        "3000": "High voltage not present, the laser was commanded before it was ready. Wait for the ignition phase to be completed and start the program again.",
        "7502": "Check that the optics covers are mounted correctly",
        "7503": "Check that the optics covers are mounted correctly",
        "7510": "Check Lens protection Glass and control Cutting Process Parameters",
        "7511": "Check Lens protection Glass and control Cutting Process Parameters",
        "9041": "Check Lens protection Glass and control Cutting Process Parameters. Stop the machine for few minutes to allow temperature to decrease",
        "9043": "Check Lens protection Glass and control Cutting Process Parameters. Stop the machine for few minutes to allow temperature to decrease",
        "9045": "Stop the machine and contact Prima Power Service",
        "9047": "Check Lens protection Glass and control Cutting Process Parameters",
        "9052": "Stop the machine and contact Prima Power Service",
        "9053": "Stop the machine and contact Prima Power Service"   
    }

    // Loop through the keys in the list_remedy object to find if any key is included in the error string
    for (const key in list_remedy) {
        if (error.includes(key)) {
            return list_remedy[key]; // Return the remedy corresponding to the key found in the error string
        }
    }

    // Return a default message if no key matches
    return "No remedy found for the specified error code.";
};
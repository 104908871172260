/**
 * The `DateRangeSelector` component in React allows users to select a date range using a date picker
 * with specific configurations.
 * @returns The `DateRangeSelector` component is being returned. It consists of a date range selector
 * UI component using the `react-datepicker` library within a `Row` component from `react-bootstrap`.
 * The component allows the user to select a date range and updates the global state with the selected
 * start and end dates.
 */
import React from "react";
import { Row, Col } from "react-bootstrap";

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { useGlobalState } from '../../components/globalStateProvider';

const ProductionDateRangeSelector= () => {
    
  const { startDateProduction, endDateProduction, setDateRangeProduction } = useGlobalState();

  const minSelectableDate = new Date("2024/01/01"); 
  const maxSelectableDate = new Date()

  return (
    <>
      <Row className="text-center mt-2 mb-1">
        <p><strong>Seleziona il periodo</strong></p>
      </Row>
      <Row className="text-center mt-1 mb-4">
        <Col md={{ span:4, offset:4 }}>
          <DatePicker
            showIcon
            selectsRange
            startDate={startDateProduction}
            endDate={endDateProduction}
            onChange={(update) => { setDateRangeProduction(update); }}
            isClearable
            withPortal
            dateFormat="yyyy/MM/dd"
            minDate={minSelectableDate}
            maxDate={maxSelectableDate}
            allowSameDay
            showWeekNumbers
            calendarStartDay={1}
          />
        </Col>

      </Row>
    </>
  );
};

export default ProductionDateRangeSelector;
import React from "react";
import { Row, Form } from "react-bootstrap";

import { useGlobalState } from '../../components/globalStateProvider';


const SNMachineList = () => {
    
  const { snMachine, setSnMachine, listSNMachines } = useGlobalState();

  return (
    <Row className="text-center mt-4 mb-4">
      <Form.Select
        aria-label="Default select example"
        as="select"
        value={snMachine}
        onChange={e => {
          if (e.target.value !== "") {
            setSnMachine(e.target.value);
          }
        }}>
        <option value="" disabled>Seleziona una matricola macchina</option>
        {listSNMachines.map((item, index) => (
          <option value={item} key={index}>{item}</option>
        ))}
      </Form.Select>
    </Row>
  );
};

export default SNMachineList;
import React, {useState, useEffect} from 'react'
import { Row, Col, Form } from "react-bootstrap";
import DataTable from 'react-data-table-component';

import { useGlobalState } from '../../components/globalStateProvider.js';

import FilterComponent from './DataTableComponents/FilterComponent'
import ColumnVisibilityManager from './DataTableComponents/ColumnVisibilityManager';
import ExportComponent, { downloadCSV } from './DataTableComponents/ExportComponent';
import { prepareData } from './DataTableComponents/DataUtils';
import SignalSensorsPlot from './SignalSensorsPlot.js'


const SignalSensorsTable = ({ columns, posts, info_sensors }) => {

  const { machineFamilySensors, infoSensors } = useGlobalState();

  const [selectedSensors, setSelectedSensors] = useState("")

  // Reset info
  useEffect(() => {
    setSelectedSensors("");
  }, [machineFamilySensors]);

  // Column visibility management
  const startColumnsHidden = ['ID', 
                              'CADID', 
                              'customerID', 
                              'deviceID', 
                              'deviceName',
                              'deviceSize', 
                              'versionNumber',
                              'created'
                            ]

  const [hiddenColumns, setHiddenColumns] = useState(startColumnsHidden);

  const toggleColumnVisibility = (columnName) => {
    if (hiddenColumns.includes(columnName)) {
      setHiddenColumns(hiddenColumns.filter((col) => col !== columnName));
    } else {
      setHiddenColumns([...hiddenColumns, columnName]);
    }
  };

  // Prepare data
  const { columnManipulation, rawManipulation } = React.useMemo(
    () => prepareData(posts, columns, hiddenColumns),
    [posts, columns, hiddenColumns]
  );

  // Filter condition
  const [filterCustomerText, setFilterCustomerText]   = useState('');
  const [filterSnMachineText, setFilterSnMachineText] = useState('');
  const [filterLaserText, setFilterLaserText]         = useState('');
  const [filterHeadText, setFilterHeadText]           = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = rawManipulation.filter(item => 
      item.customer && item.customer.toLowerCase().includes(filterCustomerText.toLowerCase()) &&
      item.snMachine && item.snMachine.toLowerCase().includes(filterSnMachineText.toLowerCase()) &&
      item.Laser && item.Laser.toLowerCase().includes(filterLaserText.toLowerCase()) &&
      item.Head && item.Head.toLowerCase().includes(filterHeadText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const snMachineOptions = [...new Set(rawManipulation.map(item => item.snMachine).filter(option => option !== null))];
    const laserTypeOptions = [...new Set(rawManipulation.map(item => item.Laser).filter(option => option !== null))];
    const headTypeOptions = [...new Set(rawManipulation.map(item => item.Head).filter(option => option !== null))];

    const handleClearCustomer = () => {
        if (filterCustomerText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterCustomerText('');
        }
    };
    const handleClearSnMachine = () => {
        if (filterSnMachineText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterSnMachineText('');
        }
    };
    const handleClearLaser = () => {
        if (filterLaserText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterLaserText('');
        }
    };
    const handleClearHead = () => {
        if (filterHeadText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterHeadText('')
        }
    };

    return (
      <FilterComponent  onFilterCustomer={e => setFilterCustomerText(e.target.value)} 
                      filterCustomer={filterCustomerText} 
                      onFilterSnMachine={e => setFilterSnMachineText(e.target.value)} 
                      filterSnMachine={filterSnMachineText}
                      onFilterLaser={e => setFilterLaserText(e.target.value)} 
                      filterLaser={filterLaserText}
                      onFilterHead={e => setFilterHeadText(e.target.value)} 
                      filterHead={filterHeadText}
                      snMachineOptions={snMachineOptions}
                      laserTypeOptions={laserTypeOptions}
                      headTypeOptions={headTypeOptions}
                      onClearCustomer={handleClearCustomer} 
                      onClearSnMachine={handleClearSnMachine}
                      onClearLaser={handleClearLaser}
                      onClearHead={handleClearHead}
        />
    );
  }, [filterCustomerText, filterSnMachineText, filterLaserText, filterHeadText, rawManipulation, resetPaginationToggle]);

  // Custom table style
  const customStyles = {
      headCells: {
      style: {
          fontWeight: 'bold',
      },
      }   
  };

  // Return table structure
  return (
    <>
      <ColumnVisibilityManager
        columns={columnManipulation}
        hiddenColumns={hiddenColumns}
        toggleColumnVisibility={toggleColumnVisibility}
      />
    
      <DataTable
          columns = {columnManipulation.filter((col) => !col.omit)}
          data = {filteredItems}
          pagination
          actions={<ExportComponent onExport={() => downloadCSV(rawManipulation)} />}
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          className='disable-sticky mt-3'
          customStyles={customStyles}
          dense 
      />

      <div className="my-spacer"></div>

      <Row className="text-center mt-4 mb-4">
          <h4>Selezionare soglia sensore da analizzare</h4>
      </Row>

      <Row className="mt-1 mb-4">
        <Col md={{ span: 6, offset: 3 }}>
          <Form.Select aria-label="Default select example" as="select"
                       value={selectedSensors}
                       onChange={e => {
                                      if (e.target.value !== "0") {
                                          console.log("e.target.value s", e.target.value);
                                          setSelectedSensors(e.target.value);
                                      }
                       }}>
            <option value="" disabled>Seleziona un sensore</option>
            {infoSensors.Sensors_to_analyze.map((item, index) => (
              <option value={item} key={index}>{item}</option>
            ))}
          </Form.Select>
        </Col>
      </Row>

      <Row className="mb-5">
        <SignalSensorsPlot data={filteredItems} sensor_type={selectedSensors}/>
      </Row>

    </>
  )
}

export default SignalSensorsTable
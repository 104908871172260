/**
 * The CustomChatBOT function creates a chatbot interface that assists users with troubleshooting
 * technical issues related to connection problems and machine status.
 * @returns The `CustomChatBOT` component is being returned, which contains the ChatBot component with
 * the specified styles, settings, and flow for a chatbot interface designed to assist users with
 * technical issues related to Prima Power products.
 */
import ChatBot, {Button} from "react-chatbotify";

const CustomChatBOT = () => {

	const typeProblems = ["Connection status Issue", "Machine status Issue"];
    const choiceYesNo = ["Yes", "No"]

	const flow = {

		start: {
			message: "Hi, I'm Prima Power BOT, how can I help you?",
            options: typeProblems,
			path: "process_options"
		},

		prompt_again: {
			message: "Do you need me to help you with anything else?",
			options: typeProblems,
			path: "process_options"
		},

		unknown_input: {
			message: "Sorry, I didn't understand your request. Please select one of the options, for further assistance please contact Prima Power Service",
			options: typeProblems,
			path: "process_options"
		},

		process_options: {
			transition: {duration: 1000},
			chatDisabled: true,
			path: async (params) => {
				switch (params.userInput.toLowerCase()) {
				case typeProblems[0].toLowerCase():
					return "connection_issue_1"
				case typeProblems[1].toLowerCase():
					return "machine_issue_1";
				default:
					return "unknown_input";
				}
			},
		},

        connection_issue_1: {
			message: "Check the network cable if it is connected correctly. Did you solve it?",
            options: choiceYesNo,
			path: "connection_issue_2"
		},

        unknown_input_connection_1: {
			message: "Sorry, I did not understand your request. Please select one of the options",
			options: choiceYesNo,
			path: "connection_issue_2"
		},

        connection_issue_2: {
            transition: {duration: 1000},
			chatDisabled: true,
			path: async (params) => {
				switch (params.userInput.toLowerCase()) {
				case "Yes".toLowerCase():
					return "end_good"
				case "No".toLowerCase():
					return "connection_issue_3";
				default:
					return "unknown_input_connection_1";
				}
			},
        },

        connection_issue_3: {
			message: "Check whether the firewalls of your IT system block data transmission (IP, blocked ports). Have you solved it?",
            options: choiceYesNo,
			path: "connection_issue_4"
		},

        unknown_input_connection_3: {
			message: "Sorry, I did not understand your request. Please select one of the options",
			options: choiceYesNo,
			path: "connection_issue_4"
		},

        connection_issue_4: {
            transition: {duration: 1000},
			chatDisabled: true,
			path: async (params) => {
				switch (params.userInput.toLowerCase()) {
				case "Yes".toLowerCase():
					return "end_good"
				case "No".toLowerCase():
					return "end_bad";
				default:
					return "unknown_input_connection_3";
				}
			},
        },

        machine_issue_1: {
			message: "Did the problem you are facing show up in the ‘Machine Status’ tab?",
			options: choiceYesNo,
			path: "machine_issue_2"
		},

        unknown_input_machine_1: {
			message: "Sorry, I did not understand your request. Please select one of the options",
			options: choiceYesNo,
			path: "machine_issue_2"
		},

        machine_issue_2: {
            transition: {duration: 1000},
			chatDisabled: true,
			path: async (params) => {
				switch (params.userInput.toLowerCase()) {
				case "Yes".toLowerCase():
					return "machine_issue_3"
				case "No".toLowerCase():
					return "end_bad";
				default:
					return "unknown_input_machine_1";
				}
			},
        },

        machine_issue_3: {
			message: "Left-click on the error text to open a detailed report with explanations and possible remedies to deal with it",
            transition: {duration: 2000},
			path: "machine_issue_4"
		},

        machine_issue_4: {
			message: "Were you able to solve it?",
			options: choiceYesNo,
			path: "machine_issue_5"
		},

        unknown_input_machine_4: {
			message: "Sorry, I did not understand your request. Please select one of the options",
			options: choiceYesNo,
			path: "machine_issue_4"
		},

        machine_issue_5: {
            transition: {duration: 1000},
			chatDisabled: true,
			path: async (params) => {
				switch (params.userInput.toLowerCase()) {
				case "Yes".toLowerCase():
					return "end_good"
				case "No".toLowerCase():
					return "end_bad";
				default:
					return "unknown_input_machine_4";
				}
			},
        },


        end_good: {
			message: "Glad to have been of help!",
			options: ["New request?"],
			chatDisabled: true,
			path: "start"
		},

        end_bad: {
			message: "Sorry I couldn't help you, for further assistance contact Prima Power Service",
			options: ["New request?"],
			chatDisabled: true,
			path: "start"
		}
	}



	// SETTINGS
	const settings = {
	general: {
		embedded: false,
		showHeader: true,
	},
	tooltip: {
		mode: "CLOSE",
		text: "Need assistance?",
	},
	header: {
		title: "Prima Power BOT",
		buttons: [Button.CLOSE_CHAT_BUTTON]
	},
	chatHistory: {
		disabled: true
	},
	footer: {
		text: "",
		buttons: []
	},
	voice: {
		disabled: false
	}
	}
	
	// STYLE
	const styles = {
	headerStyle: {
		background: '#14539A',
		color: '#ffffff',
		padding: '10px',
	},
	chatWindowStyle: {
		backgroundColor: '#f2f2f2',
	},
	tooltipStyle: {
		fontSize: '16px',
	},
	notificationBadgeStyle: {
		background: '#14539A',
		color: '#ffffff',
		fontSize: '14px'
	},
	chatButtonStyle: {
		fontSize: '14px'
	},
	};

	  
	return (
		<ChatBot styles={styles} settings={settings} flow={flow}/>
	);
};

export default CustomChatBOT;
import React, {useEffect} from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { configureAmplifyWithApiKey } from './AmplifyConfig';

import { defaultStorage } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { withAuthenticator } from '@aws-amplify/ui-react';

import MyNavbar from "./pages/components/Navbar/CustomNavbar";
import ChangePassword from "./pages/components/ChangePassword/ChangePassword";
import CreateNewUser from "./pages/components/CreateNewUser/CreateNewUser"

import HomePage from './pages/home/HomePage';

import AnalisiMacchineConnessePage from "./pages/analisi-connessione-macchine/AnalisiMacchineConnessePage";
import AnalisiOverviewMLPage from "./pages/analisi-stato-macchine/AnalisiOverviewMLPage";
import AnalisiErroreDriverPage from "./pages/analisi-errore-driver/AnalisiErroreDriverPage";
import AnalisiSegnaliSensoriPage from "./pages/analisi-segnali-sensori/AnalisiSegnaliSensoriPage"
import AnalisiProduzionePage from "./pages/analisi-produzione/AnalisiProduzionePage"
import AnalisiProduzioneClientePage from "./pages/analisi-produzione-cliente/AnalisiProduzioneClientePage"

import OverviewClientePage from "./pages/analisi-overview-cliente/OverviewClientePage"

import '@aws-amplify/ui-react/styles.css';
import './App.css'


cognitoUserPoolsTokenProvider.setKeyValueStorage(sessionStorage); // in alternative put defaultStorage
configureAmplifyWithApiKey();

// APP FUNCTION
function App({ signOut, user }) {

  useEffect(() => {
    console.log("Authenticated user:", user);
  }, [user]);

  return (
    <React.StrictMode>
      <BrowserRouter>
        <div className='font-definition'>
          <MyNavbar></MyNavbar>

          <Routes>
            {/* General Pages */}
            <Route path='/' exact element={<HomePage/>} />
            <Route path='/change-password' exact element={<ChangePassword/>} />
            {/* Admin use Pages */}
            <Route path='/create-new-user' exact element={<CreateNewUser/>} />
            {/* Internal use Pages */}
            <Route path='/analisi-connessione-macchine' exact element={<AnalisiMacchineConnessePage/>} />
            <Route path='/analisi-stato-macchine' exact element={<AnalisiOverviewMLPage/>} />
            <Route path='/analisi-errore-driver' exact element={<AnalisiErroreDriverPage/>} />
            <Route path='/analisi-segnali-sensori' exact element={<AnalisiSegnaliSensoriPage/>} />
            <Route path='/analisi-produzione' exact element={<AnalisiProduzionePage/>} />
            <Route path='/analisi-produzione-cliente' exact element={<AnalisiProduzioneClientePage/>} />
            {/* Customer use Pages */}
            <Route path='/analisi-overview-cliente' exact element={<OverviewClientePage/>} />
          </Routes>

        </div>
      </BrowserRouter>
    </React.StrictMode>

  );
}

export default withAuthenticator(App, {hideSignUp: true});